const SkeletonPresupuestosEcForm = () => {
    return (
        <div
            className="presupuestos-ec-form__container--row each-row skeleton"
        >
            <p className='p-input'></p>
            <p className='p-presupuesto'></p>
            <p className='p-fecha'></p>
            <p className='p-cliente'></p>
            <p className='p-telefono'></p>
        </div>
    );
};

export default SkeletonPresupuestosEcForm;
