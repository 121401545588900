import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ItemsTable from "./components/ItemsTable";
import { fetchFichaProveedor } from "../../../../../../redux/features/fichaProveedor";
import ProveedorMetrics from "./components/ProveedorMetrics";
import Loading from "../../../shared/components/Loading";
import ProveedorCard from "../../../shared/components/ProveedorCard";
import { toast } from "react-toastify";
import { updatePiezaPagoProveedor } from "../../../shared/services/piezas";
import { discount, getVale } from "../../../shared/services/proveedores";
import moment from "moment";
import ProveedorValeModal from "../../../shared/components/ProveedorValeModal";
import { actualizarPreciosCompra } from "../../../shared/services/presupuestos";

export default function ProveedorSheet() {
    const dispatch = useDispatch();
    const { proveedorId } = useParams();
    const [tab, setTab] = useState('compras');
    const [page, setPage] = useState(1);
    const [valeActivo, setValeActivo] = useState(null);
    const [openVale, setOpenVale] = useState(false);
    const proveedor = useSelector(state => {
        return state.fichaProveedor.entity
    });
    const status = useSelector(state => {
        return state.fichaProveedor.status
    });

    useEffect(() => {
        dispatch(fetchFichaProveedor('compras', page, proveedorId));
    }, []);

    useEffect(() => {
        if(proveedor) {
            if(tab === 'compras') {
                if(proveedor.proveedor.credito) {
                    const activeVale = proveedor.proveedor?.vales?.find(f => f.active);

                    if(activeVale) {
                        setOpenVale(true);
                        setValeActivo(activeVale);
                    } else {
                        handleCloseVale();
                    }
                } else { handleCloseVale(); }
            }
        }
    }, [proveedor]);

    const handleChangeTab = (e, tab) => {
        setTab(tab);
        setPage(1);

        if(tab !== 'metricas') dispatch(fetchFichaProveedor(tab, page, proveedorId));
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        dispatch(fetchFichaProveedor(tab, newPage, proveedorId))
    }

    const pagoProveedor = async(piezaId, pago) =>  {
        dispatch({type: 'loading/set', payload: true});
        const response = await updatePiezaPagoProveedor(piezaId, { pago_proveedor: pago});
  
        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            dispatch(fetchFichaProveedor(tab, page, proveedorId));
        }
    }

    const handleDownloadVale = async(vale) => {
        dispatch({type: 'loading/set', payload: true});
        const exportResult = await getVale(vale.id);

        if(exportResult) {
            dispatch({type: 'loading/set', payload: false});
            toast.success('Vale descargado correctamente!')
            
            const url = window.URL.createObjectURL(new Blob([exportResult]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', vale.foto);
            document.body.appendChild(link);
            link.click();
        } else {
            toast.error('Ups! Algo ha ido mal...')
        }
    }

    const handleDiscountVale = async(confirm, importe) => {
        if(confirm) {
            if(importe > 0) {
                const response = await discount({id: valeActivo.id, importe: importe});

                if(response && response.success) {
                    toast.success(response.message);
                }
            }
        }

        handleCloseVale();
    }

    const handleCloseVale = () => {
        setOpenVale(false);
        setValeActivo(null);
    }

    const handleUpdatePrecioCompra = async(data) => {
        const response = await actualizarPreciosCompra(data);

        if(response && response.success) {
            toast.success(response.data);
            handleReloadFicha();
        }
    }

    const handleReloadFicha = () => dispatch(fetchFichaProveedor(tab, page, proveedorId));

    
    if(status.loading === 'pending' || status.loading === 'idle') {
        return ( <Loading /> );
    }
    
    if(status.loading === 'succeed' || status.loading === 'rejected') {
        return (
            <div className="w-100 d-flex content">
                <div className="w-100">
                    <div className="w-100 d-flex flex-column align-items-start justify-content-start">
                        <ProveedorCard 
                            proveedor={proveedor.proveedor}
                        />
                        
                        <div className="col-12 mt-4">
                            <div className="w-100 sheet">
                                <TabContext value={tab}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChangeTab} aria-label="Ficha proveedor tabs">
                                        <Tab label="Compras" value="compras" />
                                        <Tab label="Presupuestos" value="presupuestos" />
                                        <Tab label="Devoluciones" value="devoluciones" />
                                        <Tab label="Incidencias" value="incidencias" />
                                        <Tab label="Vales" value="vales" />
                                        <Tab label="Score" value="score" />
                                        <Tab label="Métricas" value="metricas" />
                                    </TabList>
                                    </Box>
                                    <TabPanel value="compras">
                                        { proveedor.items && proveedor.items.length > 0 ?
                                            <ItemsTable 
                                                list={proveedor.items} 
                                                page={page}
                                                count={proveedor.count}
                                                proveedor={proveedor.proveedor}
                                                item={'compra'}
                                                onPagoProveedor={pagoProveedor}
                                                onPageChange={handleChangePage}
                                                onUpdatePrecioCompra={handleUpdatePrecioCompra}
                                            />
                                            : <p>No hay ventas.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="presupuestos">
                                        { proveedor.items && proveedor.items.length > 0 ?
                                            <ItemsTable 
                                                list={proveedor.items} 
                                                page={page}
                                                count={proveedor.count}
                                                proveedor={proveedor.proveedor}
                                                item={'presupuesto'}
                                                onPageChange={handleChangePage}
                                            />
                                            : <p>No hay presupuestos.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="devoluciones">
                                        { proveedor.items && proveedor.items.length > 0 ?
                                            <ItemsTable 
                                                list={proveedor.items} 
                                                page={page}
                                                count={proveedor.count}
                                                proveedor={proveedor.proveedor}
                                                item={'devolucion'}
                                                onPageChange={handleChangePage}
                                            />
                                            : <p>No hay devoluciones.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="incidencias">
                                        { proveedor.items && proveedor.items.length > 0 ?
                                            <ItemsTable 
                                                list={proveedor.items} 
                                                page={page}
                                                count={proveedor.count}
                                                proveedor={proveedor.proveedor}
                                                item={'incidencia'}
                                                onPageChange={handleChangePage}
                                            />
                                            : <p>No hay incidencias.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="vales">
                                        { proveedor.proveedor.vales && proveedor.proveedor.vales.length > 0 ?
                                            <div className="w-100 table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>fecha</th>
                                                            <th className="text-center">total</th>
                                                            <th className="text-center">gastado</th>
                                                            <th className="text-center">pendiente</th>
                                                            <th className="text-center">terminado</th>
                                                            <th className="text-center">activo</th>
                                                            <th className="text-center">acciones</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { proveedor.proveedor.vales?.map(vale => {
                                                            return (
                                                                <tr key={'vale-' + vale.id}>
                                                                    <td>{moment(vale.fecha).format('DD-MM-YYYY')}</td>
                                                                    <td className="text-center">{vale.total}€</td>
                                                                    <td className="text-center">{vale.gastado}€</td>
                                                                    <td className="text-center text-warning fw-bold">{vale.total - vale.gastado}€</td>
                                                                    <td className="text-center">
                                                                        { (vale.terminado) ?
                                                                            <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                                                                            : 
                                                                            <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        { (vale.active) ?
                                                                            <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                                                                            : 
                                                                            <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                                                        }
                                                                    </td>
                                                                    <td className="text-center" width={'8%'}>
                                                                        <span title="Descargar" className="action me-2" style={{cursor: 'pointer'}} onClick={() => handleDownloadVale(vale)}>
                                                                            <FontAwesomeIcon icon={faDownload} size="1x" color="#215732" />
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            : <p>No hay vales.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="score">
                                        <p>
                                            SCORE ACTUAL: &nbsp; 
                                            <span className={"fw-bold " + (proveedor.score >= 7 ? 'text-green' : proveedor.score >= 6.5 ? 'text-warning' : 'text-danger')}>
                                                {proveedor.proveedor.score}
                                            </span>
                                        </p>
                                        { proveedor.proveedor.score_logs && proveedor.proveedor.score_logs.length > 0 ?
                                            <div className="w-100 d-flex align-items-center justify-content-center">
                                                <div className="w-50 table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>fecha</th>
                                                                <th className="text-center">historico score</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { proveedor.proveedor.score_logs?.map((score, i) => {
                                                                return (
                                                                    <tr key={'score-log-' + score.id}>
                                                                        <td className="text-uppercase">{moment(score.fecha).format('MMMM')}</td>
                                                                        <td width={'20%'} className="text-center">{score.prev_score}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            : <p>No hay logs de score.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="metricas">
                                        { 
                                            <ProveedorMetrics proveedorId={proveedor.proveedor.id} />
                                        }
                                    </TabPanel>
                                </TabContext>
                            </div>
                           
                        </div>
                    </div>
                </div>

                { (openVale) &&
                    <ProveedorValeModal 
                        vale={valeActivo}
                        state={openVale}
                        onConfirmAction={handleDiscountVale}
                        onClose={handleCloseVale}
                    />
                }
            </div>
        );
    }
}