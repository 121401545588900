import { useState, useEffect } from "react";
import React from "react";
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from '@mui/material/Pagination';
import {disablePresupuesto, duplicatePresupuesto, enablePresupuesto, exportPresupuestosClientes, generatePresupuestoPDF, newPresupuestoFromFile, validatePresupuesto} from '../shared/services/presupuestos';
import Header from '../shared/components/Header';
import { faLink, faClock, faCheck, faCross } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmModal from "../shared/components/ConfirmModal";
import { fetchPresupuestos } from "../../../../redux/presupuestos";
import { handleCopyPresupuestoText, isWithFaros, isWithParagolpes, isWithTurbo, setErrorMessage } from "../shared/helpers/functionalities";
import { Popover } from "@mui/material";
import Loading from "../shared/components/Loading";
import { newVenta } from "../shared/services/ventas";
import InfoModal from "../shared/components/InfoModal";
import VentaForm from "./components/VentaForm";
import ExportForm from "../shared/components/ExportForm";
import ValidacionModal from "./components/ValidacionModal";
import PresupuestoFileForm from "./components/PresupuestoForm";
import { getActions } from "./actions";
import ActionsMenu from "../shared/components/ActionsMenu";
import config from '../../../../config';
import { payWithTransfer, updateTransferenciaMethod } from '../shared/services/checkout';
import PresupuestoEcForm from './components/PresupuestosEcForm';

export default function Presupuestos() {
  const user = JSON.parse(localStorage.getItem('user'));
  const dispatch = useDispatch();
  const { page } = useParams();
  const currPage = parseInt(page);
  let navigate = useNavigate(); 
  const [currentPresupuesto, setCurrentPresupuesto] = useState(null);
  const [currentPage, setPage] = useState(currPage);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [showValidate, setShowValidate] = useState(false);
  const [openConfirmVenta, setOpenConfirmVenta] = useState(false);
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [openNewFromFile, setOpenNewFromFile] = useState(false);
  const [notifyEc, setNotifyEc] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let presupuestoAux = {};
  const open = Boolean(anchorEl);
  const presupuestos = useSelector(state => {
    return state.presupuestos.entities
  });
  const presupuestosStatus = useSelector(state => {
    return state.presupuestos.status
  });
  const searcher = useSelector(state => {
    return state.search
  })

  useEffect(() => {
    dispatch(fetchPresupuestos(currPage, getQuery()));
  }, [searcher]);

  const getQuery = () => {
    const isEmpty = Object.values(searcher).every(x => x === null || x === '');
    const query = !isEmpty ? 'search=' + (searcher?.search ? searcher?.search : '') 
        + (searcher?.start_date ? '&start_date=' + searcher?.start_date : '')
        + (searcher?.end_date ? '&end_date=' + searcher?.end_date : '')
        + (searcher?.min ? '&min=' + searcher?.min : '')
        + (searcher?.max ? '&max=' + searcher?.max : '')
        + (searcher?.responsable ? '&responsable=' + searcher?.responsable : '')
      : null;

    return query;
  }

  const handleSearch = (search, searchData) => {
    setPage(1);
    navigate(`/presupuestos/${1}`);
    dispatch({type: "search/set", payload: searchData}); 
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    navigate(`/presupuestos/${newPage}`);
    dispatch(fetchPresupuestos(newPage, getQuery()));
  }

  const getPaginationCount = () => {
    var totalPages = presupuestos.total_items < 15 ? 1 : Math.ceil(presupuestos.total_items / 15);
    return parseInt(totalPages);
  }

  const handleAction = (action, presupuesto) => {
    if(action === 'details') {
      navigate(`/presupuesto/${presupuesto.id}`);
    } else  if(action === 'download') {
      generarPDF(presupuesto)
    } else  if(action === 'clone') {
      duplicate(presupuesto)
    } else  if(action === 'generate') {
      handleOpenConfirmVenta(presupuesto)
    } else if(action === 'enable') {
      reactivate(presupuesto.id)
    } else if(action === 'disable') {
      disable(presupuesto.id)
    } else if(action === 'validate') {
      handleOpenValidate(presupuesto)
    } 
  }


  const generarPDF = async (presupuesto) => {
    dispatch({type: 'loading/set', payload: true});
    const pdfResult = await generatePresupuestoPDF(presupuesto.id);

    if(pdfResult) {
      dispatch({type: 'loading/set', payload: false});
      toast.success('PDF generado correctamente!')
      const url = window.URL.createObjectURL(new Blob([pdfResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', presupuesto.numero + '.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

  const reactivate = async (presupuestoId) => {
    dispatch({type: 'loading/set', payload: true});
    const activateResult = await enablePresupuesto(presupuestoId)
    .catch(function (error) {
      toast.error(setErrorMessage(error))
      dispatch({type: 'loading/set', payload: false});
    });

    if(activateResult && activateResult.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(activateResult.message)
      dispatch(fetchPresupuestos(currPage, getQuery()));
    }
  }

  const disable = async(presupuestoId) => {
    dispatch({type: 'loading/set', payload: true});
    const disableResult = await disablePresupuesto(presupuestoId)
    .catch(function (error) {
      toast.error(setErrorMessage(error))
      dispatch({type: 'loading/set', payload: false});
    });

    if(disableResult && disableResult.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(disableResult.message)
      dispatch(fetchPresupuestos(currPage, getQuery()));
    }
  }

  const handleConfirmValidate = async(confirm) => {
    if(confirm) {
      dispatch({type: 'loading/set', payload: true});
      const activateResult = await validatePresupuesto({validacion: confirm}, currentPresupuesto.id)
      .catch(function (error) {
        toast.error(setErrorMessage(error))
        dispatch({type: 'loading/set', payload: false});
      });
  
      if(activateResult && activateResult.success) {
        dispatch({type: 'loading/set', payload: false});
        toast.success(activateResult.message)
        setShowValidate(false);
        dispatch(fetchPresupuestos(currPage, getQuery()));
      }
    } else setShowValidate(false);
  }
  
  const handleOpenValidate = (pres) => { setShowValidate(true); setCurrentPresupuesto(pres); }
  const handleCloseValidate = () => { setShowValidate(false); setCurrentPresupuesto(null); }

  const duplicate = (presupuesto) => { 
    setCurrentPresupuesto(presupuesto);
    setIsDuplicating(true);
  }

  const handleConfirmDuplicate = async (confirm) => {
    if(confirm) {
      dispatch({type: 'loading/set', payload: true});
      const duplicateResult = await duplicatePresupuesto(currentPresupuesto.id)
      .catch(function (error) {
        toast.error(setErrorMessage(error))
        dispatch({type: 'loading/set', payload: false});
      });

      if(duplicateResult && duplicateResult.success) {
        dispatch({type: 'loading/set', payload: false});
        setIsDuplicating(false);
        toast.success(duplicateResult.message)
        dispatch(fetchPresupuestos(currPage, getQuery()));
      } 
    } else {
      setIsDuplicating(false);
    }
  }

  const handleCopyText = (presupuesto) => {
    if(presupuesto.validado) {
      const text = handleCopyPresupuestoText(presupuesto, user);

      navigator.clipboard.writeText(text);
      toast.success('Texto copiado!');

      if(isWithParagolpes(presupuesto?.piezas)) {
        toast.warning(`Verificar los componentes que lleva montados el paragolpes para que al 
        cliente le llegue lo mismo que en las fotos, preguntarlo por escrito al desguace.`);
      }
  
      if(isWithFaros(presupuesto?.piezas)) {
        toast.warning(`En el caso de un faro de xenon o led, verificar si el faro viene con las centralitas, 
        informar al cliente para que no haya mal entendidos. Preguntarlo por escrito al desguace.`);
      }

      if(isWithTurbo(presupuesto?.piezas)) {
        toast.warning(`Recuerda pedir un video al desguace donde se vea el juego axial y radial del eje del turbo, 
        es importante conseguir el video antes de la venta del turbo para evitar una posible incidencia, 
        los turbos presentan una incidencia aprox. del 40%.`);
      }
    } else {
      toast.error("¡¡PRESUPUESTO NO VALIDADO!! 😛🖕🏼");
    }
  }

  const handleGoToEnvio = (envio) => {
    dispatch({type: "search/set", payload: {search: envio.codigo_transporte }});
    navigate('/envios/1', { replace : true});
  }

  const handlePopoverOpen = (event, pres) => { 
    setAnchorEl(event.currentTarget); 
    presupuestoAux = pres;
  };
  const handlePopoverClose = () => { setAnchorEl(null); };

  const handleOpenConfirmVenta = (presupuesto) => { setOpenConfirmVenta(true); setCurrentPresupuesto(presupuesto); }

  const handleNewVenta = async (data) => {
    dispatch({ type: "loading/set", payload: true });

    let response = null;

    if (data?.comprobante_bancario?.length) {
      if (!currentPresupuesto?.direccion_envio_id) {
        toast.error("El presupuesto no tiene dirección de envío");
      }

      const formData = new FormData();
      formData.append("comprobante", data.comprobante_bancario[0]);
      formData.append("plataforma_id", data.plataforma_id);
      formData.append("codigo_pedido", data.codigo_pedido);
      formData.append(
        "direccion_envio_id",
        currentPresupuesto?.direccion_envio_id
      );

      response = await payWithTransfer(
        currentPresupuesto?.checkout_id,
        formData
      ).catch(function (error) {
        toast.error(setErrorMessage(error));
      });

      await updateTransferenciaMethod(currentPresupuesto?.id, {
        tipo_pago_id: 3,
      }).catch(function (error) {
        toast.error(setErrorMessage(error));
      });
    } else {
      response = await newVenta({
        presupuesto_id: currentPresupuesto?.id,
        tipo_pago_id: 5,
        plataforma_id: data.plataforma_id,
        codigo_pedido: data.codigo_pedido,
      }).catch(function (error) {
        toast.error(setErrorMessage(error));
      });
    }

    if (response && response.success) {
      toast.success(response.message);
      setOpenConfirmVenta(false);
      setCurrentPresupuesto(null);
      dispatch(fetchPresupuestos(currPage, getQuery()));
    }

    dispatch({ type: "loading/set", payload: false });
  };

  const handleOpenExport = () => { setIsOpenExport(true); }
  const handleCloseExport = () => { setIsOpenExport(false); }
  const handleExportPresupuestosClientes = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const cliente = data.cliente;
    delete data.cliente;
    data.entity_id = cliente.id;
    const excelResult = await exportPresupuestosClientes(data);
    const startDate = moment(data.startDate).format('DD-MM-YYYY');
    const endDate = moment(data.endDate).format('DD-MM-YYYY');

    if(excelResult) {
      dispatch({type: 'loading/set', payload: false});
      toast.success('PDF generado correctamente!')
      const url = window.URL.createObjectURL(new Blob([excelResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', cliente.nombre + '_' + startDate + '_' + endDate + '.xlsx');
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

  const handleOpenNewFromFile = () => setOpenNewFromFile(true);
  const handleCloseNewFromFile = () => setOpenNewFromFile(false);
  const handleNewFromFile = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await newPresupuestoFromFile(data);

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      handleCloseNewFromFile();
      dispatch(fetchPresupuestos(currPage, getQuery()));
    }
  }

  const handleOpenNotifyEc = () => setNotifyEc(true);
  const handleCloseNotifyEc = () => setNotifyEc(false);

  if(presupuestosStatus.loading === 'pending' || presupuestosStatus.loading === 'idle') {
    return ( <Loading /> );
  }

  if(presupuestosStatus.loading === 'succeed' || presupuestosStatus.loading === 'rejected') {
    const pageCount = getPaginationCount();

    return (
      <div className="content pt-3">
        <Header  
          viewSearch={true} 
          onNew={handleOpenNewFromFile}
          onSearch={handleSearch}
          onExport={handleOpenExport}
          onNotify={handleOpenNotifyEc}
        ></Header>

        <div className="w-100 d-flex flex-column align-items-end content-table presupuestos">
          <div className="w-100 d-flex align-items-center justify-content-between justify-content-md-start mb-3">
            <div className="legend d-flex align-items-center me-md-3">
              <span className="circle" style={{background: '#ffc107'}}></span>
              <p>Devolución parcial</p>
            </div>

            <div className="legend d-flex align-items-center">
              <span className="circle" style={{background: '#dc3545'}}></span>
              <p>Devolución total</p>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Nº</th>
                  <th className="text-center">fecha</th>
                  <th className="text-center"></th>
                  <th>cliente</th>
                  <th>telf</th>
                  <th><small>Pieza</small></th>
                  <th><small>URL</small></th>
                  <th className="text-center"><small>compra</small></th>
                  <th className="text-center"><small>venta</small></th>
                  <th className="text-center"><small>casco</small></th>
                  <th className="text-center">descuento</th>
                  <th className="text-center">total IVA</th>
                  <th className="text-center">Envío</th>
                  <th className="text-center">conf</th>
                  <th className="text-center">aceptado</th>
                  <th className="text-center">resp</th>
                  <th className="text-center"></th>
                </tr>
              </thead>
                { 
                  presupuestos.presupuestos?.map((presupuesto, index) => {
                    const piezasValues = Object.values(presupuesto.piezas)
                    const piezasRows = piezasValues.map((pieza, i) => {
                      const envioPieza = presupuesto?.envios?.find(e => e.piezas?.find(p => p.id === pieza.id));
                      const servicioEnvioPieza = pieza.servicio_envio;
                      
                      const numero = i === 0 ? <td rowSpan={piezasValues.length + 1} width="6%">
                        <span className="disabled-item" title="Presupuesto" style={{cursor: 'pointer'}} onClick={() =>  handleCopyText(presupuesto)} >
                          { presupuesto.es_devolucion.length > 0 && presupuesto.es_devolucion[0].tipo_devolucion_id === 1 ?
                            <b className="text-warning">{presupuesto?.numero}</b>
                            : presupuesto.es_devolucion.length > 0 && presupuesto.es_devolucion[0].tipo_devolucion_id === 2 ?
                            <b className="text-danger">{presupuesto?.numero}</b>
                            : presupuesto.factura_proveedor && presupuesto.factura_proveedor?.length > 0 ?
                            <b>{presupuesto?.numero}</b>
                            : presupuesto?.numero
                          }
                        </span>
                      </td> : null;

                      const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className="text-center">
                        <div className="w-100 d-flex flex-column">
                          <p>{moment(presupuesto.fecha).format("DD-MM-YYYY")}</p>
                          <small>CREADO: {moment(presupuesto.created_at).format("DD-MM-YYYY HH:mm")}</small>
                          <small>ULT MOD.: {moment(presupuesto.updated_at).format("DD-MM-YYYY HH:mm")}</small>
                        </div>
                      </td> : null
                      const checkout = i === 0 ? 
                        <td rowSpan={piezasValues.length + 1} className="text-center">
                            <a title="Checkout URL" href={config.recomotor.clientsUrl + 'checkout/' + presupuesto.checkout_id} target={"_blank"}  className="disabled-item text-green" rel="noreferrer">
                              <FontAwesomeIcon icon={faLink} size="1x" className="action" style={{ cursor: 'pointer' }} color="#215732" />
                            </a>   
                        </td> 
                      : null
                      const cliente = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%">
                        <Link target="_blank" rel="noopener noreferrer" to={"/cliente/ficha/" + presupuesto.cliente?.id} title="Ficha Cliente" className="text-green text-uppercase">
                          {presupuesto.cliente?.nombre}
                        </Link>
                      </td> : null
                      const clienteTelf = i === 0 ?  <td rowSpan={piezasValues.length + 1}>{presupuesto.cliente.telefono}</td> : null
                      const aceptado = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center"> {presupuesto.expirado ? <FontAwesomeIcon icon={faCross} size="1x" className="action" color="#dc3545" /> : presupuesto.aceptado ? <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#215732" /> : <FontAwesomeIcon icon={faClock} size="1x" className="action" color="#ffc107" />}</td> : null
                      const descuento = i === 0 ? <td rowSpan={piezasValues.length + 1} className="text-center">
                      { presupuesto?.vale_descuento ? 
                        <div className="d-flex flex-column">
                          -{parseFloat(presupuesto.total_descuento_iva).toFixed(2)}€
                          <small className="fw-bold text-green">{presupuesto?.vale_descuento.codigo}</small>
                        </div>
                        : '-'
                      }
                    </td> : null
                      const totalIva = i === 0 ? <td rowSpan={piezasValues.length + 1} width="6%" className="text-center">{presupuesto.total_iva}€</td> : null
                      const envio = envioPieza ?
                          <div className="d-flex flex-column">
                            <span onClick={() => handleGoToEnvio(envioPieza)} className="text-green link">{envioPieza.codigo_transporte}</span>
                            <small>{envioPieza.transportista_id === 1 ? 'PROVEEDOR' : envioPieza.codigo_envio}</small>
                          </div>
                          : (servicioEnvioPieza) ?
                            <div className="text-uppercase d-flex flex-column">
                              <span className="text-green">{servicioEnvioPieza.transportista?.nombre}</span>
                              <small style={{fontSize: '10px'}}>{servicioEnvioPieza.servicio_envio?.nombre}</small>
                            </div>
                          : 
                          <div className="d-flex flex-column">
                            <small className="text-danger fw-bold text-uppercase">falta envio</small>
                          </div>
                      const confirmada = <span className={"fw-bold " + (pieza.confirmacion == true ? 'text-green' : 'text-danger')}>
                        {pieza.confirmacion == true ? 'SI' : 'NO'}
                      </span>
                      const responsable = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center" width="6%"><small style={{wordBreak: 'break-all'}} className="text-uppercase">{presupuesto.created_by?.username}</small></td> : null
                      const acciones = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center">
                        <ActionsMenu 
                            options={getActions(presupuesto, user)}
                            onAction={(action) => handleAction(action, presupuesto)}
                        />
                      </td> : null
                          
                      return (
                        <tr
                            className={(((!presupuesto.validado && presupuesto.validacion === null) || (presupuesto.validado && presupuesto.validacion === false) || (presupuesto.validado_integracion === false))) ? "disabled-row" : ""}
                            key={i}
                            onMouseEnter={(!presupuesto.validado && presupuesto.validacion === null) ? (e => handlePopoverOpen(e, presupuesto)) : null}
                            onMouseLeave={(!presupuesto.validado && presupuesto.validacion === null) ? handlePopoverClose : null}
                          >
                            {numero}
                            {fecha}
                            {checkout}
                            {cliente}
                            {clienteTelf}
                            <td className="border-light" style={{backgroundColor: pieza.disponible === null ? '#fff1ae' : !pieza.disponible ? '#fab3ae' : 'none' }}>
                              <div className="w-100">
                                <small className="w-100 d-flex flex-column align-items-start">
                                  <span>{pieza?.presupuesto_relationship?.unidades > 1 ? <strong>{'x'+pieza?.presupuesto_relationship?.unidades}</strong> : '' } {pieza.nombre}</span>
                                  { pieza?.casco > 0 ?
                                    <span className="fw-bold text-uppercase highlight"><small>contiene casco</small></span>
                                    : ''
                                  }
                                </small>
                              </div>
                            </td>
                            <td className="text-center border-light">
                              <a href={pieza.url} className="text-green" target={"_blank"} rel="noreferrer">
                                <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>
                              </a>
                            </td>
                            <td className="text-center border-light">{parseFloat(pieza.precio_compra).toFixed(2)}€</td>
                            <td className="text-center border-light">{parseFloat(pieza.precio_venta).toFixed(2)}€</td>
                            <td className="text-center">{pieza.casco > 0 ? parseFloat(pieza.casco).toFixed(2) + '€' : '-'}</td>
                            {descuento}
                            {totalIva}
                            <td className="text-center border-light">{envio}</td>
                            <td className="text-center border-light">{confirmada}</td>
                            {aceptado}
                            {responsable}
                            {acciones}
                        </tr>
                      )
                    })
                    return (
                      <tbody className="border-light" key={index}>
                        {piezasRows}
                      </tbody>
                    )
                  }) 
                }
            </table>

            <>
            <Popover
              id="disabledPresupuesto"
              sx={{ pointerEvents: 'none'}}
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              disableRestoreFocus
              classes={{ root: "MuiPopover-root" }}
            >
              <small>
                {(!presupuestoAux.validado && presupuestoAux.validacion === null) ? 
                  "Presupuesto pendiente de validar"
                  : (!presupuestoAux.validado && !presupuestoAux.validacion) ?
                  "Presupuesto NO VALIDADO"
                  : ""
                }
              </small>
            </Popover>
            </>
          </div>

          <Pagination 
            className="mt-3" 
            count={pageCount} 
            page={currentPage} 
            onChange={handleChangePage} 
          />
        </div>

        { isDuplicating && 
          <ConfirmModal 
            onConfirmAction={handleConfirmDuplicate} 
            title={'Duplicar presupuesto'} 
            description={'Estás seguro de duplicar?'}
            state={isDuplicating}>
          </ConfirmModal>
        }

        { showValidate && 
          <ValidacionModal 
            presupuestoId={currentPresupuesto.id}
            onConfirmAction={handleConfirmValidate} 
            onClose={handleCloseValidate}
            state={showValidate}>
          </ValidacionModal>
        }

        { openConfirmVenta &&
          <InfoModal
              state={openConfirmVenta}
              title={"Generar venta " + currentPresupuesto.numero}
              content={
                  <VentaForm
                    onSubmitVenta={handleNewVenta}
                  />
              }
              width={'sm'}
              onClose={() => { setOpenConfirmVenta(false); }}
          ></InfoModal>
        }

        { (isOpenExport) && 
          <InfoModal
            state={isOpenExport}
            title="Exportar listado de presupuestos de un cliente"
            content={
              <ExportForm 
                dateFilter={true}
                clienteFilter={true}
                onSubmitExport={handleExportPresupuestosClientes}
              />
            }
            onClose={handleCloseExport}
          ></InfoModal>
        }

        { openNewFromFile &&
          <InfoModal
              state={openNewFromFile}
              title="Crear presupuesto desde archivo"
              content={
                  <PresupuestoFileForm 
                    onSubmitPresupuesto={handleNewFromFile}
                  />
              }
              width={'md'}
              onClose={() => { handleCloseNewFromFile(); }}
          ></InfoModal>
        }
        
        { notifyEc &&
          <InfoModal
              state={notifyEc}
              title="Presupuestos Ecommerce no finalizados"
              content={
                  <PresupuestoEcForm 
                    onSubmitPresupuesto={handleNewFromFile}
                  />
              }
              width={'md'}
              onClose={() => { handleCloseNotifyEc(); }}
          ></InfoModal>
        }
      </div>
    )
  }
}