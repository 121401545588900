import { faCheck, faChevronDown, faFilePdf, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useState, useEffect } from "react";
import React from "react";
import { useDispatch } from "react-redux";
import { generateFacturaPDF } from "../../../shared/services/facturas_desguaces";
import { toast } from "react-toastify";
import InfoModal from "../../../shared/components/InfoModal";
import FacturasList from "./components/FacturasList";
import moment from "moment";

export default function Vehiculo({
    vehiculo,
    expanded,
    onChange
}) {
    const dispatch = useDispatch();
    const [piezas, setPiezas] = useState(null);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState('todas');
    const [facturasList, setFacturasList] = useState(null);
    const [openFacturas, setOpenFacturas] = useState(false);

    useEffect(() => {
        if(vehiculo && vehiculo?.piezas) {
            setPiezas(vehiculo.piezas);
        }
    }, [vehiculo, expanded]);

    const getDaysBetween = (created, sold) =>  {
        if(!created || !sold) return '-';
        const formatedCreated =  new Date(created.split("T")[0]);
        const formatedSold =  new Date(sold);
        const diffTime = Math.abs(formatedSold - formatedCreated);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearch(value);
        setFilter('todas');

        if(value === "") {
            setPiezas(vehiculo.piezas);
        } else {
            const newList = vehiculo.piezas.filter(f => f.id_referencia_plataforma?.toLowerCase()?.includes(value) || f.referencia?.toLowerCase()?.includes(value));
            setPiezas(newList);
        }
    }

    const handleChangeFilter = (e) => {
        const value = e.target.value;
        setFilter(value);
        setSearch('');
        if(value === 'todas') {
            setPiezas(vehiculo.piezas);
        } else {
            const newList = vehiculo.piezas.filter(f => f.venta ? value === 'vendidas' : value === 'no_vendidas');
            setPiezas(newList);
        }
    }

    const handleOpenVehiculoFacturas = (facturas) => {
        setFacturasList(facturas);
        setOpenFacturas(true);
    }
    const handleCloseVehiculoFacturas = () => {
        setOpenFacturas(false);
        setFacturasList(null);
    }

    const generateThePdf = async (factura) => {
        dispatch({type: 'loading/set', payload: true});
        const pdfResult = await generateFacturaPDF(factura.id);
  
        if(pdfResult) {
          dispatch({type: 'loading/set', payload: false});
          toast.success('PDF generado correctamente!')
          const url = window.URL.createObjectURL(new Blob([pdfResult]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', factura.numero + '.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
        } else {
          toast.error('Ups! Algo ha ido mal...')
        }
    }

    return (
        <Accordion 
            expanded={expanded === vehiculo.bastidor} 
            onChange={onChange(vehiculo.bastidor)}
            TransitionProps={{ unmountOnExit: true }}
        >
            <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" className="action" color="#215732" />}
                aria-controls={'controls-' + vehiculo.bastidor}
                id={vehiculo.bastidor}
            >
                <p>{vehiculo.bastidor} | {vehiculo.nombre}</p>
            </AccordionSummary>
            <AccordionDetails>
                <div className="w-100">
                    <div className="w-100 d-flex align-items-center justify-content-between mb-4">
                        <div className="d-flex gap-2">
                            <div className="position-relative">
                                <input 
                                    type="text" 
                                    name="searcher" 
                                    className="form-control" 
                                    placeholder="ID | referencia"
                                    value={search} 
                                    onChange={handleSearch}  />
                                <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" />
                            </div>
                            <select 
                                value={filter}
                                onChange={handleChangeFilter}
                                style={{width: '140px', height: '43px'}}
                            >
                                <option value={'todas'}>Todas</option>
                                <option value={'vendidas'}>Vendidas</option>
                                <option value={'no_vendidas'}>No vendidas</option>
                            </select>
                        </div>

                        <button className="btn btn-add" onClick={() => vehiculo?.facturas === 1 ? generateThePdf(vehiculo.facturas[0]) : handleOpenVehiculoFacturas(vehiculo.facturas)}>
                            <span className="add">
                                <FontAwesomeIcon icon={faFilePdf} size="1x" className="action" color="#215732" />
                            </span>
                            {vehiculo?.facturas === 1 ? 'Factura vehiculo' : 'Facturas del vehiculo'}
                        </button>
                    </div>

                    {(vehiculo?.piezas) &&
                    <>
                        <div className="w-100 d-flex align-items-center justify-content-between mb-4">
                            <p>Fecha: <b>{moment(vehiculo.facturas[0].fecha).format('DD-MM-YYYY')}</b></p>
                            <p>Total vehiculo: <b>{vehiculo.total}€</b></p>
                            <p>Total piezas vendidas: <b>{vehiculo.total_piezas_vendidas}€</b></p>
                            <p>Resultado: <b>{vehiculo.resultado}€</b></p>
                            <p>Piezas vendidas: <b>{vehiculo.num_piezas_vendidas}</b></p>
                            <p>Total vendido: <b>{vehiculo.porcentaje_vendidas}</b></p>
                        </div>

                        <div className="w-100 table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>pieza</th>
                                        <th className="text-center">referencia</th>
                                        <th className="text-center">precio</th>
                                        <th className="text-center">vendida</th>
                                        <th className="text-center">precio venta</th>
                                        <th className="text-center">fecha de almacenamiento</th>
                                        <th className="text-center">fecha venta</th>
                                        <th className="text-center">días en rotación</th>
                                        <th className="text-center">beneficio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { piezas?.length > 0 ?
                                        piezas?.map((pieza, i) => {
                                            return (
                                                <tr key={'pieza-' + i + '-' + vehiculo.bastidor}>
                                                    <td>{pieza.id_referencia_plataforma}</td>
                                                    <td>{pieza.articulo}</td>
                                                    <td className="text-center">{pieza.referencia}</td>
                                                    <td className="text-center">{parseFloat(pieza.precio).toFixed(2)}€</td>
                                                    <td className="text-center">
                                                        <div className="d-flex flex-column align-items-center">
                                                            { pieza?.venta ? 
                                                                <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                                                                : 
                                                                <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                                            }
                                                            
                                                            { (Array.isArray(pieza.presupuesto)) ?
                                                                <div className="d-flex flex-column">
                                                                    { pieza.presupuesto?.map(pres => {
                                                                        return (
                                                                            <small key={'presupuesto-' + pres?.id}>{pres?.numero}</small> 
                                                                        )
                                                                    })}
                                                                </div>
                                                                :
                                                                <small>{pieza.presupuesto?.numero}</small>
                                                            }
                                                            
                                                            <small>
                                                                {pieza.venta &&
                                                                    <>
                                                                        { pieza.venta?.facturas?.length > 0 ?
                                                                          pieza.venta?.facturas[0].numero :
                                                                          pieza.venta?.albaranes[0].numero
                                                                        }
                                                                    </>
                                                                }
                                                            </small>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">{pieza?.venta ? (parseFloat(pieza.precio_venta) + '€') : '-'}</td>
                                                    <td className="text-center">
                                                        {
                                                            (pieza.fecha_almacenada && moment(pieza.fecha_almacenada).format('DD/MM/YY')) ||
                                                            (pieza.created_at && moment(pieza.created_at).format('DD/MM/YY')) ||
                                                            '-'
                                                        }
                                                    </td>
                                                    <td className="text-center">{pieza.venta?.fecha  ? moment(pieza.venta?.fecha).format('DD/MM/YY') : '-'}</td>
                                                    <td className="text-center">{getDaysBetween(pieza.fecha_almacenada || pieza.created_at, pieza.venta?.fecha, pieza)}</td>
                                                    <td className="text-center">{pieza?.venta ? (parseFloat(pieza.precio_venta - pieza.precio).toFixed(2) + '€') : '-'}</td>
                                                </tr>
                                            )
                                        })
                                        : 
                                        <tr>
                                            <td className="text-center" colSpan={7}>No hay piezas</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>

                    </>
                    }

                    { (openFacturas) && 
                        <InfoModal
                            state={openFacturas}
                            title={`Facturas vehiculo ${vehiculo.bastidor} | ${vehiculo.nombre}`}
                            content={
                                <FacturasList 
                                    facturas={facturasList}
                                    onDownload={generateThePdf}
                                />
                            }
                            width={'md'}
                            onClose={handleCloseVehiculoFacturas}
                        ></InfoModal>
                    }
                </div>
            </AccordionDetails>
        </Accordion>
    )
}