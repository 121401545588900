import { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function NuevaReservaForm({ onReservarPieza }) {
  /** Form **/
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      recambista: "",
      id_referencia_plataforma: "",
    },
  });

  /** Lifecycle **/
  useEffect(() => {
    reset();
    const currentUser = JSON.parse(localStorage.getItem("user"));
    setValue("recambista", currentUser.username);
  }, []);

  /** Methods **/
  const handleSubmitNuevaReserva = async (data, e) => {
    e.preventDefault();
    onReservarPieza(data);
  };

  /** Render **/
  return (
    <div>
      <form
        className="form align-items-center"
        onSubmit={handleSubmit(handleSubmitNuevaReserva)}
      >
        <div className="w-100 d-flex flex-column flex-grow-1">
          <div className="form-group">
            <label htmlFor="recambista">Recambista</label>
            <input
              type="text"
              className="form-control"
              disabled
              {...register("recambista")}
            />
          </div>
          <div className="form-group">
            <label htmlFor="id_referencia_plataforma">
              ID Referencia Plataforma
            </label>
            <input
              type="number"
              className="form-control"
              {...register("id_referencia_plataforma", { required: true })}
            />
            <div className="form-invalid">
              {errors.id_referencia_plataforma?.type === "required" &&
                "ID Referencia Plataforma es requerido"}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center align-self-end mt-3">
          <button type="submit" className="btn btn-primary">
            Guardar
          </button>
        </div>
      </form>
    </div>
  );
}
