import ActionsMenu from "../shared/components/ActionsMenu";
import ConfirmModal from "../shared/components/ConfirmModal";
import InfoModal from "../shared/components/InfoModal";
import Loading from "../shared/components/Loading";
import NuevaReservaForm from "./components/NuevaReservaForm";
import Searcher from "./components/Searcher";
import { getActions } from "./actions";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import {
  fetchPiezasReservadas,
  addReserva,
  updateReserva,
} from "../../../../redux/piezasReservadas";

export default function PiezasReservadas() {
  /** State **/
  const [openReservarPieza, setOpenReservarPieza] = useState(false);
  const [openConfirmarQuitar, setOpenConfirmarQuitar] = useState(false);
  const [currentReserva, setCurrentReserva] = useState(null);
  const [
    filteredPiezasReservadasEntities,
    setFilteredPiezasReservadasEntities,
  ] = useState([]);

  /** Redux **/
  const dispatch = useDispatch();
  const piezasReservadas = useSelector((state) => {
    const { piezas_reservadas } = state;
    return piezas_reservadas;
  });

  /** Lifecycle **/
  useEffect(() => {
    dispatch(fetchPiezasReservadas());
  }, []);
  useEffect(() => {
    setFilteredPiezasReservadasEntities(piezasReservadas.entities);
  }, [piezasReservadas]);

  /** Methods **/
  const handleBuscarPieza = (data) => {
    if (!data.buscador) {
      setFilteredPiezasReservadasEntities(piezasReservadas.entities);
      return;
    }
    const piezasReservadasEntities = piezasReservadas.entities.filter(
      (pieza) => {
        return pieza.id?.toLowerCase().includes(data.buscador?.toLowerCase());
      }
    );
    setFilteredPiezasReservadasEntities(piezasReservadasEntities);
  };
  const handleReservarPieza = (data) => {
    setOpenReservarPieza(false);
    dispatch(addReserva(data));
    dispatch(fetchPiezasReservadas());
  };
  const handleAction = (action, id) => {
    if (action === "remove") {
      handleQuitarButtonPress(id);
    }
  };
  const handleQuitarButtonPress = (id) => {
    setOpenConfirmarQuitar(true);
    setCurrentReserva(id);
  };
  const handleQuitarReserva = (confirmed) => {
    setOpenConfirmarQuitar(false);
    if (!confirmed) return;
    dispatch(updateReserva(currentReserva));
    dispatch(fetchPiezasReservadas());
  };

  /** Render **/
  return (
    <>
      <div className="content pt-5">
        <h1 className="page-name">Piezas reservadas</h1>
        <div className="w-100 d-flex flex-column align-items-end content-table">
          <div className="d-flex align-items-center justify-content-between mb-3 w-100">
            <Searcher onSearch={handleBuscarPieza} />
            <button
              className="btn btn-info me-3"
              onClick={() => setOpenReservarPieza(true)}
            >
              Nueva reserva
            </button>
          </div>

          {filteredPiezasReservadasEntities?.length ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Id referencia plataforma</th>
                    <th>Pieza</th>
                    <th>Referencia</th>
                    <th>Recambista</th>
                    <th className="text-center">Acciones</th>
                  </tr>
                </thead>

                <tbody>
                  {filteredPiezasReservadasEntities?.map((pieza) => {
                    return (
                      <tr key={pieza.id}>
                        <td>{pieza.id}</td>
                        <td>{pieza.nom}</td>
                        <td>{pieza.ref}</td>
                        <td>{pieza.recambista || "-"}</td>
                        <td className="text-center">
                          <ActionsMenu
                            options={getActions()}
                            onAction={(action) =>
                              handleAction(action, pieza.id)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="d-flex items-center w-100">
              {piezasReservadas.status?.loading === "pending" ||
              piezasReservadas.loading === "idle" ? (
                <Loading />
              ) : null}
            </div>
          )}
        </div>
      </div>
      {openReservarPieza && (
        <InfoModal
          state={openReservarPieza}
          title={"Marcar pieza como reservada"}
          content={<NuevaReservaForm onReservarPieza={handleReservarPieza} />}
          width={"sm"}
          onClose={() => setOpenReservarPieza(false)}
        />
      )}
      {openConfirmarQuitar && (
        <ConfirmModal
          state={openConfirmarQuitar}
          title={"Quitar reserva"}
          onConfirmAction={handleQuitarReserva}
          content={"Estas seguro de quitar esta reserva?"}
          width={"sm"}
          onClose={() => setOpenConfirmarQuitar(false)}
        />
      )}
    </>
  );
}
