import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchFichaCliente } from "../../../../../../redux/features/fichaCliente";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ItemsTable from "./components/ItemsTable";
import ClienteMetrics from "./components/ClienteMetrics";
import Loading from "../../../shared/components/Loading";
import EntityCard from "../../../shared/components/EntityCard";
import ValesDescuentosTable from "./components/ValesDescuentoTable";

export default function ClienteSheet() {
    const dispatch = useDispatch();
    const { clienteId } = useParams();
    const [tab, setTab] = useState('compras');
    const [page, setPage] = useState(1);
    const cliente = useSelector(state => {
        return state.fichaCliente.entity
    });
      const status = useSelector(state => {
        return state.fichaCliente.status
    });

    useEffect(() => {
        dispatch(fetchFichaCliente('compras', page, clienteId));
    }, []);

    const handleChangeTab = (e, tab) => {
        setTab(tab);
        setPage(1);

        if(tab !== 'metricas' && tab !== 'vales' && tab !== 'comentarios') {
            dispatch(fetchFichaCliente(tab, page, clienteId));
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        dispatch(fetchFichaCliente(tab, newPage, clienteId))
    }
    
    if(status.loading === 'pending' || status.loading === 'idle') {
        return ( <Loading /> );
    }
    
    if(status.loading === 'succeed' || status.loading === 'rejected') {
        return (
            <div className="w-100 d-flex content">
                <div className="w-100">
                    <div className="w-100 d-flex flex-column align-items-start justify-content-start">
                        <EntityCard
                            cliente={cliente.cliente} 
                            canEdit={false}
                            canChange={false}
                        />
                        
                        <div className="col-12 mt-4">
                            <div className="w-100 sheet">
                                <TabContext value={tab}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChangeTab} aria-label="Ficha cliente tabs">
                                        <Tab label="Compras" value="compras" />
                                        <Tab label="Presupuestos" value="presupuestos" />
                                        <Tab label="Devoluciones" value="devoluciones" />
                                        <Tab label="Incidencias" value="incidencias" />
                                        <Tab label="Vales descuento" value="vales" />
                                        <Tab label="Comentarios" value="comentarios" />
                                        <Tab label="Métricas" value="metricas" />
                                    </TabList>
                                    </Box>
                                    <TabPanel value="compras">
                                        { cliente.items && cliente.items.length > 0 ?
                                            <ItemsTable 
                                                cliente={cliente.cliente} 
                                                list={cliente.items}
                                                page={page}
                                                count={cliente.count}
                                                item={'venta'}
                                                onPageChange={handleChangePage}
                                            />
                                            : <p>No hay ventas.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="presupuestos">
                                        { cliente.items && cliente.items.length > 0 ?
                                            <ItemsTable 
                                                cliente={cliente.cliente} 
                                                list={cliente.items}
                                                item={'presupuesto'}
                                                page={page}
                                                count={cliente.count}
                                                onPageChange={handleChangePage}
                                            />
                                            : <p>No hay presupuestos.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="devoluciones">
                                        { cliente.items && cliente.items.length > 0 ?
                                            <ItemsTable 
                                                cliente={cliente.cliente} 
                                                list={cliente.items}
                                                item={'devolucion'}
                                                page={page}
                                                count={cliente.count}
                                                onPageChange={handleChangePage}
                                            />
                                            : <p>No hay devoluciones.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="incidencias">
                                        { cliente.items && cliente.items.length > 0 ?
                                            <ItemsTable 
                                                cliente={cliente.cliente} 
                                                list={cliente.items}
                                                item={'incidencia'}
                                                page={page}
                                                count={cliente.count}
                                                onPageChange={handleChangePage}
                                            />
                                            : <p>No hay incidencias.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="vales">
                                        { cliente.vales && cliente.vales.length > 0 ?
                                            <ValesDescuentosTable 
                                                list={cliente.vales}
                                            />
                                            : <p>No hay vales de decuento.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="comentarios">
                                        { cliente.comentarios && cliente.comentarios.length > 0 ?
                                           <div className="w-100 d-flex flex-column">
                                                { cliente.comentarios.map(comentario => {
                                                    return (
                                                        <div className="d-flex flex-column comentario mb-3" key={'comentario-'+cliente.cliente.id + '-' + comentario.id}>
                                                            <span className="mb-1">{comentario.fecha}</span>
                                                            <p>{comentario.observaciones}</p>
                                                        </div>
                                                    )
                                                })}
                                           </div>
                                            : <p>No hay comentarios.</p>
                                        }
                                    </TabPanel>
                                    <TabPanel value="metricas">
                                        { <ClienteMetrics clienteId={cliente.cliente.id} />}
                                    </TabPanel>
                                </TabContext>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}