import React, { useEffect, useState } from "react";
import PlacesAutocomplete, 
{ geocodeByAddress, geocodeByPlaceId } from "react-places-autocomplete";
import { toast } from "react-toastify";
import './autocomplete.scss';


export default function GooglePlacesAutocomplete({
    type,
    types,
    defaultValue,
    country,
    disabled,
    onSelectResult
}) {
    const [selected, setSelected] = useState('');

    useEffect(() => {
      if(defaultValue) {
        setSelected(defaultValue);
      }
    }, [country, defaultValue, disabled]);

    const handleSelectPlace = (place) => {
        setSelected(place);
    }

    const findComponent = (source, type) => 
        source?.address_components?.find(c => c.types.includes(type))?.long_name || null;

    const extractData = (source) => ({
        extra: findComponent(source, 'subpremise') || '',
        num: findComponent(source, 'street_number'),
        street: findComponent(source, 'route'),
        postalCode: findComponent(source, 'postal_code'),
        city: findComponent(source, 'locality'),
        adminArea: findComponent(source, 'administrative_area_level_1'),
        adminArea2: findComponent(source, 'administrative_area_level_2'),
        countryName: findComponent(source, 'country'),
    });

    const handleSelect = async (address, placeId) => {
        let result = null;
        const [place] = await geocodeByPlaceId(placeId);

        let data = extractData(place);

        if (Object.values(data).some((value) => value === null)) {
            const [results] = await geocodeByAddress(address);
            const fallbackData = extractData(results);

            const getMoreOptions = (options) => {
              return options.reduce((value, option) => value || findComponent(place, option), null);
            };

            data = {
                ...data,
                num: data.num || fallbackData.num,
                street: data.street || fallbackData.street,
                postalCode: data.postalCode || fallbackData.postalCode,
                city: data.city || fallbackData.city
                    || getMoreOptions(['administrative_area_level_3', 'sublocality', 'postal_town']),
                adminArea: data.adminArea || fallbackData.adminArea,
                adminArea2: data.adminArea2 || fallbackData.adminArea2
                    || getMoreOptions(['administrative_area_level_3', 'locality', 'postal_town']),
                countryName: data.countryName || fallbackData.countryName,
            };
        }

        const { extra, num, street, postalCode, city, adminArea, adminArea2, countryName } = data;

        if (type === 'address') {
            if (!street || !postalCode || !city || !adminArea2 || !num) {
                if (!num && !postalCode) {
                    toast.warning('El número de calle es obligatorio para esta dirección');
                    return;
                }
                if (!street || !postalCode || !adminArea2) {
                    toast.warning('Faltan datos en la dirección');
                    return;
                }
            }
            result = {
                direccion: place.types.find(t => t === 'establishment') ? `${street}, ${num} ${extra}` : selected,
                codigo_postal: postalCode,
                poblacion: city,
                area: `${adminArea2 || city || countryName}, ${adminArea || countryName}`,
            };
        } else {
            if (!postalCode || !city || !adminArea2) {
                if (!adminArea2) {
                    if (!adminArea) {
                        toast.warning('No hay provincia asignada');
                        return;
                    }
                } else {
                    toast.warning('No hay código postal');
                    return;
                }
            }
            result = {
                codigo_postal: postalCode,
                poblacion: city,
                provincia: adminArea2 ? adminArea2 : adminArea,
                area: adminArea
            };
        }
        onSelectResult(result);
    };

    return (
        <PlacesAutocomplete
          value={selected}
          searchOptions={{
            types,
            language: 'es',
            componentRestrictions: {
              country: [country],
            }
          }}
          onChange={handleSelectPlace}
          onSelect={handleSelect}
          onError={(status, clearSuggestions) => {
            if(status === 'ZERO_RESULTS') {
              console.log('No hay resultados');
              toast.warning('No hay resultados');
            } else {
              console.log('Google Maps API returned error with status: ', status)
              toast.error('Google Maps API returned error with status: ', status)
            }
            
            clearSuggestions()
          }}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className="places-search">
              <input
                {...getInputProps({
                  placeholder: 'Busca una dirección ...',
                  className: 'form-control',
                  disabled: disabled,
                })}
              />
              <div className="places-search-container">
                {loading && <div className="suggestion-item">Loading...</div>}
                {suggestions.map((suggestion, i) => {
                  const className = suggestion.active
                    ? 'suggestion-item-active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      key={'place-suggestion-' + i}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      );
}