import { toast } from "react-toastify";
import { getVehiculo, getVehiculos, getFechasAlmacenamiento } from "../../views/Dashboard/components/shared/services/vehiculos";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "../utils";
import { setErrorMessage } from "../../views/Dashboard/components/shared/helpers/functionalities";

const initialDashboard = [];

export const superDashboardVehiculosReducer = (state = initialDashboard, action) => {
    switch(action.type){
        case "vehiculos/fullfilled": {
            return action.payload;
        }
        case "vehiculo/fullfilled": {
            const vehiculosList = state.vehiculos;
            const index = vehiculosList.findIndex((vehiculo) => vehiculo.bastidor === action.payload.bastidor);
            const newVehiculoInfo = {...action.payload.vehiculo, ...vehiculosList[index]};
            vehiculosList.splice(index, 1, newVehiculoInfo);

           return {
                ...state,
                vehiculos: vehiculosList,
            }

        }
        default:
            return state
    }
}

const asyncDashboardVehiculos = makeAsyncTypes('vehiculos');
const [setPending, setFullFilled, setError ] = asyncMac(asyncDashboardVehiculos);

export const dashboardVehiculosFetchingReducer = makeFetchingReducer([
    'vehiculos/pending', 
    'vehiculos/fullfilled', 
    'vehiculos/rejected'
]);

export const fetchDashboardVehiculos = (page, search) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getVehiculos(page, search);
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message)
    }
}

export const fetchDashboardVehiculo = (bastidor) => async dispatch => {
    dispatch({type: 'loading/set', payload: true});

    try {
        const fechasAlmacenamiento = await getFechasAlmacenamiento(bastidor)
        const response = await getVehiculo(bastidor)
        .catch(function (error) {
            toast.error(setErrorMessage(error))
            dispatch({type: 'loading/set', payload: false});
        });
        const data = await response.data;
        data.piezas = data.piezas.map((pieza) => {
            return {
                ...pieza,
                fecha_almacenada: fechasAlmacenamiento.data?.find((fechaAlmacenamiento) => 
                    pieza.id_referencia_plataforma === fechaAlmacenamiento.id_referencia_plataforma
                )?.fecha_almacenada,
            }
        })
        dispatch({type: 'vehiculo/fullfilled', payload: {bastidor, vehiculo: data}});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message)
    }
}

export const dashboardVehiculosReducer = superDashboardVehiculosReducer;
export const dashboardVehiculosStatusReducer = dashboardVehiculosFetchingReducer