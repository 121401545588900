export const SidebarData = [
    {
        title: 'Presupuestos',
        path: '/presupuestos',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'Ventas',
        path: '/ventas',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'Devolución de mercancías',
        path: '/devoluciones-mercancias',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'Recordatorios clientes',
        path: '/recordatorios',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'Clientes',
        path: '/clientes',
        cName: 'nav-text',
        permission: 1
    },
    {
        title: 'Envios',
        path: '/envios',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'Finanzas',
        path: '/finanzas',
        cName: 'nav-text',
        permission: 4
    },
    {
        title: 'Facturas',
        path: '/facturas',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'Incidencias',
        path: '/incidencias',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'Dashboard',
        path: '/',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'Piezas Reservadas',
        path: '/piezas-reservadas',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'Vehiculos',
        path: '/vehiculos',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'Users',
        path: '/users',
        cName: 'nav-text',
        permission: 1
    },
    {
        title: 'Proveedores',
        path: '/proveedores',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'Comerciales',
        path: '/comerciales',
        cName: 'nav-text',
        permission: 3
    },
    {
        title: 'TecDoc',
        path: '/tecdoc',
        cName: 'nav-text',
        permission: 0
    }
]