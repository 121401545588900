import API from "../../../../../services/api";

export const getPiezasReservadas = async () => {
  return await API.get("/piezas-reservadas").then((res) => res.data);
};

export const addReservaPieza = async (data) => {
  return await API.put(`/piezas-reservadas/add/${data.id_referencia_plataforma}`, data).then((res) => res.data);
}

export const updateReservaPieza = async (id) => {
  return await API.put(`/piezas-reservadas/update/${id}`).then((res) => res.data);
}