export const getActions = () => {
  return {
    actions: [
      {
        label: "Quitar reserva",
        icon: "delete",
        action: "remove",
      },
    ],
    extra: [],
  };
};
