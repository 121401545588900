import { useEffect, useState } from 'react';
import { getPendientesEcommerce, sendEmailPendientesEc } from '../../../shared/services/presupuestos';
import { toast } from 'react-toastify';
import { setErrorMessage } from '../../../shared/helpers/functionalities';
import './presupuestosEcForm.scss';
import SkeletonPresupuestosEcForm from './SkeletonPresupuestosEcForm';
import { CircularProgress } from '@mui/material';

export default function PresupuestoEcForm() {
    const [presupuestosToNotify, setPresupuestosToNotify] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
        try {
            if(isLoading) return;
            setIsLoading(true);
            const arrPresupuestos = presupuestosToNotify.filter(presupuesto => presupuesto.notify)
            const res = await sendEmailPendientesEc(arrPresupuestos);
            if(res.success) {
                const pending = presupuestosToNotify.filter(presupuesto => !presupuesto.notify)
                setPresupuestosToNotify([...pending, ...res.data]);
                if(res.data.find(presupuesto => presupuesto.isError)) {
                    toast.error('No se han podido enviar todos los emails.')
                } else {
                    toast.success('Emails enviados correctamente.');
                }
            }
        } catch (error) {
            toast.error(setErrorMessage(error));
        } finally {
            setIsLoading(false)
        }
    };

    const handleNotify = (e, presupuesto) => {
        const newPresupuestosArr = presupuestosToNotify.map((each) =>
            each.id === presupuesto.id
                ? { ...each, notify: e.target.checked }
                : each
        );
        setPresupuestosToNotify(newPresupuestosArr);
    };

    const getPendientesEcReq = async () => {
        try {
            const res = await getPendientesEcommerce();
            if (res.success) {
                setPresupuestosToNotify(res.data);
            }
        } catch (error) {
            toast.error(setErrorMessage(error));
        }
    };

    useEffect(() => {
        getPendientesEcReq();
    }, []);

    return (
        <div className="presupuestos-ec-form">
            <div className="presupuestos-ec-form__container">
                <div className="presupuestos-ec-form__container--row header">
                    <p></p>
                    <p>PRESUPUESTO</p>
                    <p>FECHA</p>
                    <p>CLIENTE</p>
                    <p>EMAIL</p>
                </div>
                {!presupuestosToNotify ? (
                    <>
                        <SkeletonPresupuestosEcForm />
                    </>
                ) : presupuestosToNotify?.length > 0 ? (
                    presupuestosToNotify?.map((presupuesto) => (
                        <div
                            key={presupuesto.id}
                            className="presupuestos-ec-form__container--row each-row"
                        >
                            <p>
                                <input
                                    type="checkbox"
                                    defaultChecked={presupuesto.notify}
                                    onChange={(e) =>
                                        handleNotify(e, presupuesto)
                                    }
                                />
                            </p>
                            <p>{presupuesto.numero}</p>
                            <p>{presupuesto.fecha}</p>
                            <p>{presupuesto.cliente.nombre}</p>
                            <p>{presupuesto.cliente.email}</p>
                        </div>
                    ))
                ) : (
                    <div className="presupuestos-ec-form__container--no-data">
                        No hay presupuestos pendientes de notificar
                    </div>
                )}
            </div>

            <div className="d-flex align-items-center mt-4">
                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                >
                    {isLoading ? <CircularProgress size="27px" color="inherit" /> : 'Enviar Email'}
                </button>
            </div>
        </div>
    );
}
