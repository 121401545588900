import { useState, useEffect } from "react";
import React from "react";
import { useNavigate, useParams  } from "react-router-dom";
import { toast } from "react-toastify";
import moment from 'moment';
import Header from '../shared/components/Header'
import { useDispatch, useSelector } from "react-redux";
import { fetchClientes } from "../../../../redux/clientes";
import { comprasExport, comprasMargenExport, extraccionFacturas, fusionarClientes, newEntidad, updateEntidad } from "../shared/services/clientes";
import InfoModal from "../shared/components/InfoModal";
import ExportForm from "../shared/components/ExportForm";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import EntityTable from "./components/EntityTable";
import Loading from "../shared/components/Loading";
import { newDireccion, updateDireccion } from "../shared/services/direcciones";
import ClienteModal from "../shared/components/ClienteModal";
import { setErrorMessage } from "../shared/helpers/functionalities";
import DetalleForm from "../shared/components/DetalleForm";
import { newDetalleComercial } from "../shared/services/comerciales";
import FusionForm from "./components/FusionForm";

export default function Clientes() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page } = useParams();
  const currPage = parseInt(page); 
  const currTab = localStorage.getItem('clientes-tab');
  const [currentPage, setPage] = useState(currPage);
  const [tab, setTab] = useState(currTab ? currTab : 'cliente');
  const [currentCliente, setCurrentCliente] = useState(null);
  const [isExportClientes, setIsExportClientes] = useState(false);
  const [isExportClientesMargen, setIsExportClientesMargen] = useState(false);
  const [isExportExtraccion, setIsExportExtraccion] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenNewDetalle, setIsOpenNewDetalle] = useState(false);
  const [isFusion, setIsFusion] = useState(false);
  
  const clientesStatus = useSelector(state => {
    return state.clientes.status
  });
  const searcher = useSelector(state => {
    return state.search
  })
  
  useEffect(() => {
    dispatch(fetchClientes(currentPage, getQuery(tab)));
  }, [searcher]);

  const getQuery = (section = null) => {
    let query;
    if(searcher) {
      const isEmpty = Object.values(searcher).every(x => x === null || x === '');
      query = !isEmpty ? 'seccion=' + section + '&search=' + (searcher?.search ? searcher?.search : '')
        + ([0, 1].includes(searcher?.credito) ? '&credito=' + searcher?.credito : '')
      : 'seccion=' + (section ? section : tab);
    } else query = 'seccion=' + (section ? section : tab);

    return query;
  }

  const handleSearch = (search, searchData) => {
    navigate(`/clientes/${1}`);
    dispatch({type: "search/set", payload: searchData});     
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    navigate(`/clientes/${newPage}`);
    dispatch(fetchClientes(newPage, getQuery(tab)));
  }

  const handleChangeTab = (e, newTab) => { 
    setTab(newTab);
    setPage(1);
    navigate(`/clientes/${1}`);
    localStorage.setItem('clientes-tab', newTab); 
    dispatch(fetchClientes(1, getQuery(newTab)));
  }

  const handleExportCompras = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const exportResult = await comprasExport(data);

    if(exportResult) {
      dispatch({type: 'loading/set', payload: false});
      const today = moment().format('YYYY-MM-DD');
      toast.success('Listado de compras descargado correctamente!')
    
      const url = window.URL.createObjectURL(new Blob([exportResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'compras_B2B_' + today + '.xlsx');
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }

    setIsExportClientes(false);
  }

  const handleExportComprasMargen = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const exportResult = await comprasMargenExport(data);

    if(exportResult) {
      dispatch({type: 'loading/set', payload: false});
      toast.success('Listado de compras descargado correctamente!')
    
      const url = window.URL.createObjectURL(new Blob([exportResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'compras_margen.xlsx');
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }

    setIsExportClientesMargen(false);
  }

  const handleExportExtraccion = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const cliente = data.cliente;
    data.entity_id = cliente.id;
    delete data.cliente;
    const exportResult = await extraccionFacturas(data);

    if(exportResult) {
      dispatch({type: 'loading/set', payload: false});
      const start = moment(data.startDate).format('DD-MM-YYYY');
      const end = moment(data.endDate).format('DD-MM-YYYY');
      toast.success('Extracción descargada correctamente!')
    
      const url = window.URL.createObjectURL(new Blob([exportResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'desglose_albaranes_' + cliente.nombre + '_' + start + '_' + end + '.xlsx');
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }

    setIsExportExtraccion(false);
  }

  const handleExport = (item) => { 
    if(item === 'ventas') setIsExportClientes(true);
    else if(item === 'margen') setIsExportClientesMargen(true);
    else if(item === 'extraccion') setIsExportExtraccion(true);
  }
  const handleCloseExportClientes = () => setIsExportClientes(false);
  const handleCloseExportClientesMargen = () => setIsExportClientesMargen(false);
  const handleCloseExtraccion = () => setIsExportExtraccion(false);

  const handleOpenClienteForm = (cliente) => { setIsOpenForm(true); setCurrentCliente(cliente); }
  const handleCloseClienteForm = () => { setIsOpenForm(false); }

  const handleSubmitCliente = async(cliente) => {
    let response;
    dispatch({type: 'loading/set', payload: true});

    if(cliente.id !== "") {
      response = await updateEntidad(cliente);
    } else {
      delete cliente.id;
      response = await newEntidad(cliente);
    }

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      setIsOpenForm(false);
      setCurrentCliente(null);
      dispatch(fetchClientes(currentPage, getQuery(tab)));
    }
  }

  const handleSubmitDireccion = async(isEdit, direccion) => {
    let response;
    dispatch({type: 'loading/set', payload: true});

    if(isEdit) {
      response = await updateDireccion(direccion);
    } else {
      response = await newDireccion(direccion);
    }

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      setIsOpenForm(false);
      setCurrentCliente(null);
      dispatch(fetchClientes(currentPage, getQuery(tab)));
    }
  }

  const handleOpenNewDetalle = (cliente) => { setIsOpenNewDetalle(true); setCurrentCliente(cliente); }
  const handleCloseNewDetalle = () => { setIsOpenNewDetalle(false); }
  const handleNewDetalle = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await newDetalleComercial(data);

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      setIsOpenNewDetalle(false);
      setCurrentCliente(null);
      dispatch(fetchClientes(currentPage, getQuery(tab)));
    }
  }

  const handleOpenFusion = (cliente) => { 
    setCurrentCliente(cliente);
    setIsFusion(true); 
  }
  const handleCloseFusion = () => setIsFusion(false);
  const handleFusion = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await fusionarClientes(data)
    .catch(function (error) {
        toast.error(setErrorMessage(error))
        dispatch({type: 'loading/set', payload: false});
    });
    
    if(response && response.success) { 
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      dispatch(fetchClientes(currentPage, getQuery(tab)));
      setIsFusion(false);
    }
  }

  if(clientesStatus.loading === 'pending' || clientesStatus.loading === 'idle') {
    return ( <Loading /> );
  }

  if(clientesStatus.loading === 'succeed' || clientesStatus.loading === 'rejected') {
    return (
      <div className="content pt-3">
        <Header 
          onNew={() => handleOpenClienteForm(null)}
          viewSearch={true}
          onSearch={handleSearch}
          onExport={handleExport}
        ></Header>

        <div className="w-100 mt-2">
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="Tipos de clientes">
              <Tab label="Clientes" value="cliente" />
              <Tab label="Leads" value="lead" />
              <Tab label="Contactos" value="contacto" />
            </TabList>
            </Box>
            <TabPanel value="cliente">
                <EntityTable 
                  id={'cliente'}
                  currPage={currentPage}
                  onEdit={handleOpenClienteForm}
                  onPageChange={handleChangePage}
                  onNewDetalle={handleOpenNewDetalle}
                  onFusion={handleOpenFusion}
                />
            </TabPanel>
            <TabPanel value="lead">
                <EntityTable 
                  id={'lead'}
                  currPage={currentPage}
                  onEdit={handleOpenClienteForm}
                  onPageChange={handleChangePage}
                  onFusion={handleOpenFusion}
                />
            </TabPanel>
            <TabPanel value="contacto">
                <EntityTable 
                  id={'contacto'}
                  currPage={currentPage}
                  onEdit={handleOpenClienteForm}
                  onPageChange={handleChangePage}
                  onFusion={handleOpenFusion}
                />
            </TabPanel>
          </TabContext>
        </div>

        { (isOpenForm) &&
          <ClienteModal
            fromPresupuesto={false} 
            open={isOpenForm}
            cliente={currentCliente}
            telefono={null}
            selectedDireccionId={null}
            isEdit={currentCliente ? true : false}
            onSubmitCliente={handleSubmitCliente}
            onSubmitDireccion={handleSubmitDireccion}
            onClose={handleCloseClienteForm}
          />
        }

        { (isOpenNewDetalle) && 
          <InfoModal
            state={isOpenNewDetalle}
            title="Nuevo detalle comercial"
            content={
              <DetalleForm
                cliente={currentCliente}
                onSubmitCliente={handleNewDetalle}  
              />
            }
            width={'sm'}
            onClose={handleCloseNewDetalle}
          ></InfoModal>
        }
        
        { (isExportClientes) && 
          <InfoModal
            state={isExportClientes}
            title="Exportar número de ventas a Excel"
            content={
              <ExportForm 
                dateFilter={true}
                tipoClienteFilter={true}
                tipoFacturasFilter={false}
                responsableFilter={false}
                onSubmitExport={handleExportCompras}
              />
            }
            onClose={handleCloseExportClientes}
          ></InfoModal>
        }

        { (isExportExtraccion) && 
          <InfoModal
            state={isExportExtraccion}
            title="Exportar extracción de desglose de facturas a crédito"
            content={
              <ExportForm 
                dateFilter={true}
                clienteFilter={true}
                tipoClienteFilter={false}
                tipoFacturasFilter={false}
                responsableFilter={false}
                onSubmitExport={handleExportExtraccion}
              />
            }
            onClose={handleCloseExtraccion}
          ></InfoModal>
        }

        { (isExportClientesMargen) && 
          <InfoModal
            state={isExportClientesMargen}
            title="Exportar ventas con margen a Excel"
            content={
              <ExportForm 
                dateFilter={true}
                tipoClienteFilter={true}
                tipoFacturasFilter={false}
                responsableFilter={false}
                onSubmitExport={handleExportComprasMargen}
              />
              }
            onClose={handleCloseExportClientesMargen}
          ></InfoModal>
        }

        { isFusion && 
          <InfoModal
            width={'lg'}
            state={isFusion}
            title=""
            content={
              <FusionForm 
                cliente={currentCliente}
                onSubmitFusion={handleFusion}
               />
            }
            onClose={handleCloseFusion}
          ></InfoModal>
        }
      </div>
    )
  } 
}