

import { faInfoCircle, faPrint, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getInfoEnvios } from "../../services/envios";
import EXPRESSWAY from '../../../../../../images/expressway.png';
import PROVEEDOR from '../../../../../../images/proveedor.png';
import CLIENTE from '../../../../../../images/cliente.png';
import SEUR from '../../../../../../images/shippingServices/seur.png';
import MRW from '../../../../../../images/shippingServices/mrw.png';
import TXT from '../../../../../../images/shippingServices/TXT.png';
import DHL from '../../../../../../images/shippingServices/dhl.png';
import { CustomTooltip, setErrorMessage } from "../../helpers/functionalities";
import Loading from "../Loading";
import GooglePlacesAutocomplete from "../GooglePlacesAutocomplete";
import { useWatch } from "react-hook-form";
import { toast } from "react-toastify";

export default function EnvioSearcher({
    fromPresupuesto,
    fromSection,
    proveedor,
    pieza,
    tipoPiezaId,
    cliente,
    from,
    to,
    medidas,
    valor,
    direccionEnvio,
    setValue,
    getValues,
    register,
    onSelectServicio,
    onSeguroSelected,
    tipoClienteDestino
}) {
    const paises = useSelector(state => {
        const { paises } = state;
        return paises;
    });
    const recogida = {
        etiqueta: true,
        img: null,
        informacion: null,
        nombre_servicio: "Recoge el cliente",
        nombre_transportista: "",
        porcentaje_seguro: 8,
        precio_tarifa: 2,
        precio_transporte: 2,
        recomendado: false,
        servicio_envio_id: 57,
        transportista_id: 1,
        recogida_id: null,
    };
    const [isLoading, setIsLoading] = useState(false);
    const [optionsList, setOptionsList] = useState([recogida]);
    const [serviciosEnvioList, setServicioEnvioList] = useState([recogida]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [origen, setOrigen] = useState(null);
    const [paisOrigen, setPaisOrigen] = useState(1);
    const [destino, setDestino] = useState(null);
    const [paisDestino, setPaisDestino] = useState(1);
    const [seguro, setSeguro] = useState(false);
    const [seguroDisabled, setSeguroDisabled] = useState(false);
    const [isVoluminoso, setIsVoluminoso] = useState(false);
    const inputRef = useRef(null);
    const watchedValues = useWatch([
        'pais_origen_id',
        'pais_destino_id',
        'remitente_codigo_postal',
        'destinatario_codigo_postal'
    ]);

    useEffect(() => {
        if(fromPresupuesto) {
            const destinoStorage = localStorage.getItem('destino_presupuesto') ?
            JSON.parse(localStorage.getItem('destino_presupuesto')) : null;

            const areaOrigen = proveedor.area?.split(', ');

            let poblacionOrigen = {
                codigo_postal: proveedor.codigo_postal,
                poblacion: proveedor.poblacion,
                provincia: areaOrigen[0],
                area: areaOrigen[1],
                pais_id: proveedor.pais_id,
                pais: paises.find(p => p.id === parseInt(proveedor.pais_id))?.codigo || 'ES'
            };
            
            if(proveedor.poblacion && proveedor.area) {
                setPaisOrigen(poblacionOrigen.pais);
                setValue('pais_origen_id', poblacionOrigen.pais_id);
                setOrigen(poblacionOrigen);
                setValue('origen', poblacionOrigen);
            }

            if(destinoStorage) {
                setDestino(destinoStorage);
                setValue('destino', destinoStorage);
                setPaisDestino(destinoStorage?.pais);
                setValue('pais_destino_id', destinoStorage?.pais_id);
            }

            if(isPuertasPortonesCapots(tipoPiezaId)) {
                setIsVoluminoso(true);
            }
    
            if(direccionEnvio || cliente) {
                let poblacionDestino = null;

                if(cliente) {
                    if(cliente.direcciones?.length === 1) {
                        const areaDestino = cliente.direcciones[0].area?.split(', ');
                        poblacionDestino =  {
                            codigo_postal: cliente.direcciones[0].codigo_postal,
                            poblacion: cliente.direcciones[0].poblacion,
                            provincia: areaDestino[0],
                            area: areaDestino[1],
                            pais_id: cliente.direcciones[0].pais_id,
                            pais: paises.find(p => p.id === parseInt(cliente.direcciones[0].pais_id))?.codigo || 'ES'
                        };
                        
                        setPaisDestino(poblacionDestino.pais_id);
                    }
                }

                if(direccionEnvio) {
                    const areaDestino = direccionEnvio.area?.split(', ');

                    poblacionDestino =  {
                        codigo_postal: direccionEnvio.codigo_postal,
                        poblacion: direccionEnvio.poblacion,
                        provincia: areaDestino[0],
                        area: areaDestino[1],
                        pais_id: direccionEnvio.pais_id,
                        pais: paises.find(p => p.id === parseInt(direccionEnvio.pais_id))?.codigo || 'ES'
                    };
                    setPaisDestino(poblacionDestino.pais_id);
                }
               
                if(poblacionDestino) {
                    setDestino(poblacionDestino);
                    setValue('destino', poblacionDestino);
                    setPaisDestino(poblacionDestino?.pais);
                    setValue('pais_destino_id', poblacionDestino?.pais_id);
                }
            }
        } 
        
    }, [proveedor, direccionEnvio, fromPresupuesto, fromSection, tipoClienteDestino]);

    useEffect(() => {
        if(!fromPresupuesto) {
            const areaOrigen = from.area?.split(', ');
            const areaDestino = to.area?.split(', ');     
            const poblacionOrigen = {
                ...from,
                provincia: areaOrigen[0],
                area: areaOrigen[1],
            };
            const poblacionDestino =  {
                ...to,
                provincia: areaDestino[0],
                area: areaDestino[1],
            };
    
            setOrigen(poblacionOrigen);
            setValue('origen', poblacionOrigen);
            setPaisOrigen(from.pais_id);
            setValue('pais_origen_id', poblacionOrigen.pais_id);
            
            setDestino(poblacionDestino);
            setValue('destino', poblacionDestino);
            setPaisDestino(to.pais_id);
            setValue('pais_destino_id', poblacionDestino.pais_id);
        }
    }, [from, to, fromSection, fromPresupuesto, tipoClienteDestino]);

    

    const handleChangeSeguro = (e) => { 
        setValue('seguro', e.target.checked);
        setSelectedOption(null);
        setSeguro(e.target.checked); 
        let optionsListAux;
        const checked = e.target.checked;

        if(checked) {
            optionsListAux = optionsList.map(option => {
                if(option.transportista_id >= 2) {
                    let valorPaquete, precioTransporte, porcentajeSeguro = option.porcentaje_seguro/100;
    
                    if(fromPresupuesto) valorPaquete = pieza.precio_venta;
                    else valorPaquete = valor;
    
                    precioTransporte = (parseFloat(option.precio_transporte) + (porcentajeSeguro * valorPaquete));
                    return {...option, precio_transporte: parseFloat(precioTransporte).toFixed(2) }
                } else return {...option}
            })
        } else optionsListAux = optionsList;
        
        setServicioEnvioList(optionsListAux);
        if(!fromPresupuesto) onSeguroSelected(checked);
    }

    const handleSearchOpcionesEnvio = async() => {
        setIsLoading(true);
        let info = {};
        info.from = fromPresupuesto ? 'presupuesto' : (fromSection ? fromSection : 'envio');
        info.medidas = medidas;
        info.origen = origen;
        info.destino = destino;
        info.proveedor_id = proveedor ? proveedor.id : null;
        info.tipo_cliente_destino = tipoClienteDestino;

        if(fromPresupuesto) {
            localStorage.setItem('destino_presupuesto', JSON.stringify(info.destino));
        }

        const response = await getInfoEnvios(info)
        .catch(function (error) {
            toast.error(setErrorMessage(error.message))
        });

        if(response.success) {
            let options;

            if(fromPresupuesto) {
                let servicios = comprobarServicios(response.data);
                options = servicios;
            } else options = [...response.data];

            
            options = options.sort((a,b) => a.precio_transporte - b.precio_transporte);
            options.unshift(recogida);

            setServicioEnvioList(options);
            setOptionsList(options);
            setIsLoading(false);

            if(proveedor) {
                const withSeguro = ((tipoPiezaId >= 29 && tipoPiezaId <= 35) || (tipoPiezaId >= 1307 && tipoPiezaId <= 1311)) || 
                    ((tipoPiezaId >= 38 && tipoPiezaId <= 50) || (tipoPiezaId >= 1473 && tipoPiezaId <= 1488));
                
                if(withSeguro) {
                    inputRef.current.click();
                    setSeguroDisabled(true);
                } else { setSeguroDisabled(false); }
            }
        }
    }

    const isPuertasPortonesCapots = (tipoPiezaId) => {
        return (tipoPiezaId === 80 || (tipoPiezaId >= 146 && tipoPiezaId <= 151) ||
        (tipoPiezaId >= 174 && tipoPiezaId <= 176) || tipoPiezaId === 184);
    }

    const comprobarServicios = (servicios) => {
        let serviciosPrecios = servicios.map(servicio => {
            if(servicio.servicio_envio_id === 1 && servicio.transportista_id === 1) {
                if(!isPuertasPortonesCapots(tipoPiezaId)) {
                    servicio.precio_transporte = parseFloat(pieza.precio_transporte);
                }
            }  

            return servicio;
        });

        return serviciosPrecios
    }

    const handleSelectServicio = (servicio, i) => {
        servicio.seguro = seguro;
        
        if(seguro) {
            if(fromPresupuesto) {servicio.valor_asegurado = pieza.precio_venta}
            else  {servicio.valor_asegurado = valor;}
        } else {servicio.valor_asegurado = 0;}

        setSelectedOption(i);

        if(fromPresupuesto) { onSelectServicio(servicio, proveedor);}
        else { onSelectServicio(servicio); }
    }

    const handleChangePaisOrigen = async(e) => { 
        const value = parseInt(e.target.value);
        setPaisOrigen(value); 
        setValue('pais_origen_id', value);
        setOrigen(null);
        setValue('origen', null);
    }
    const handleChangePaisDestino = async(e) => { 
        const value = parseInt(e.target.value);
        setPaisDestino(value); 
        setValue('pais_destino_id', value);
        setDestino(null);
        setValue('destino', null);
    }

    const handleChangePrecioEnvio = (e, option) => {
        const value = parseFloat(e.target.value);

        const optionsListAux = optionsList.map(op => {
            if(op.transportista_id === option.transportista_id && op.servicio_envio_id === option.servicio_envio_id) {
                return {...op, precio_transporte: value.toFixed(2) }
            } else return {...op}
        });

        setServicioEnvioList(optionsListAux);
    }


    const handleChangeRecodigaID = (e, option) => {
        const value = e.target.value;

        const optionsListAux = optionsList.map(op => {
            if(op.transportista_id === option.transportista_id && op.servicio_envio_id === option.servicio_envio_id) {
                return {...op, recogida_id: value }
            } else return {...op}
        });

        setServicioEnvioList(optionsListAux);
    }

    const handleChangeOrigen = (origen) => {
        origen.pais_id = getValues('pais_origen_id');
        origen.pais = paises.find(p => p.id === parseInt(origen.pais_id))?.codigo
        setOrigen(origen);
        setValue('origen', origen);
        setPaisOrigen(origen.pais_id); 
        setValue('pais_origen_id', origen.pais_id);
    }

    const handleChangeDestino = (destino) => {
        destino.pais_id = getValues('pais_destino_id');
        destino.pais = paises.find(p => p.id === parseInt(destino.pais_id))?.codigo
        setDestino(destino);
        setValue('destino', destino);
        setPaisDestino(destino.pais_id); 
        setValue('pais_destino_id', destino.pais_id);
    }

    return (
        <div className="w-100">
            <div className="w-100 d-flex flex-column">
                <div className="w-100 d-flex align-items-center">
                    <div className="form-group">
                        <label className="mb-2" htmlFor="pais_origen_id">ORIGEN</label>
                        <div className="w-100 mb-3">
                            <select
                                {...register("pais_origen_id")}
                                defaultValue={paisOrigen}
                                disabled={true}
                                onChange={handleChangePaisOrigen}>
                                {paises?.map(op => <option key={'paises-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                        </div>

                        <GooglePlacesAutocomplete
                            type={'postal_code'}
                            types={['(regions)']}
                            defaultValue={origen ? (origen?.codigo_postal + ' - ' + origen?.poblacion) : ''}
                            disabled={true}
                            country={watchedValues ? paises?.find(p => p.id === parseInt(getValues('pais_origen_id')))?.codigo : 'ES'}
                            onSelectResult={handleChangeOrigen}
                        />

                        { (fromPresupuesto && !origen) &&
                            <p className="my-2">Falta información de origen</p>
                        }
                    </div>

                    <div className="form-group ms-3">
                        <label className="mb-2" htmlFor="pais_destino_id">DESTINO</label>

                        <div className="w-100 mb-3">
                            <select
                                {...register("pais_destino_id")}
                                defaultValue={paisDestino}
                                onChange={handleChangePaisDestino}>
                                {paises.map(op => <option key={'paises-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                        </div>
                        
                        <GooglePlacesAutocomplete
                            type={'postal_code'}
                            types={['(regions)']}
                            defaultValue={destino ? (destino?.codigo_postal + ' - ' + destino?.poblacion) : ''}
                            country={watchedValues? paises?.find(p => p.id === parseInt(getValues('pais_destino_id')))?.codigo : 'ES'}
                            onSelectResult={handleChangeDestino}
                        />
                    </div>

                    <button 
                        type="button" 
                        className="btn btn-primary ms-3" 
                        onClick={() => handleSearchOpcionesEnvio()}
                        disabled={(origen === null || destino === null)}
                    >BUSCAR</button>
                </div>

                <div className="w-100">
                    <div className="d-flex align-items-center justify-content-end">
                        <div className="border border-warning rounded p-2 d-flex align-items-center">
                            <input 
                                type="checkbox"
                                name="seguro"
                                id="seguro"
                                ref={inputRef}
                                disabled={seguroDisabled}
                                onChange={(e) => handleChangeSeguro(e)}
                                checked={seguro} />

                            <label className="mb-0 ms-3">
                                Seguro de envío
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-100 mt-3">
                <div className="w-100">
                    <div className="w-100 table-responsive">
                        <table className="table">
                            <tbody>
                                { !isLoading ? 
                                <>
                                { serviciosEnvioList?.length > 0 ?
                                 serviciosEnvioList?.map((option, i) => {
                                    return (
                                        <tr key={'servicio-envio-' + i} className={i === selectedOption ? 'highlighted' : ''}>
                                            <td>
                                                { option.transportista_id === 1 ?
                                                    <>
                                                        { (option.servicio_envio_id === 57) ?
                                                            <img src={CLIENTE} style={{height: '25px'}} alt="Transportista" />
                                                            :
                                                            <img src={PROVEEDOR} style={{height: '25px'}} alt="Transportista" />
                                                        }
                                                    </>
                                                    
                                                    : option.transportista_id === 2 ?
                                                    <img src={EXPRESSWAY} style={{height: '25px'}} alt="Transportista" />
                                                    : option.transportista_id === 12 ?
                                                    <img src={SEUR} style={{height: '25px'}} alt="Transportista" />
                                                    : option.transportista_id === 17 || option.transportista_id === 21 ?
                                                    <img src={MRW} style={{height: '25px'}} alt="Transportista" />
                                                    : option.transportista_id === 19 ?
                                                    <img src={TXT} style={{height: '25px'}} alt="Transportista" />
                                                    : option.transportista_id === 18 ?
                                                    <img src={option.img} style={{height: '67px', width: '102px'}} alt="Logistica" />
                                                    : option.transportista_id === 22 ?
                                                    <img src={DHL} style={{height: '25px'}} alt="DHL" />
                                                    :
                                                    <img src={option.img} style={{height: '67px', width: '102px'}} alt="Transportista" />
                                                }
                                            </td>
                                            <td className="align-middle">
                                                <div className="w-100 d-flex align-items-center">
                                                    { (option.informacion) &&
                                                        <CustomTooltip
                                                            title={
                                                                <React.Fragment>
                                                                    <p style={{whiteSpace: 'pre-line'}}>{option.informacion}</p>
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <span style={{cursor: 'pointer'}}>
                                                                <FontAwesomeIcon icon={faInfoCircle} size="1x" className="action" color="#62B1F6" /> 
                                                            </span>
                                                        </CustomTooltip>
                                                    }

                                                    <div className="d-flex flex-grow-1 flex-column ms-2">
                                                        <p className="m-0">
                                                            {option.nombre_transportista} {option.nombre_servicio}
                                                        </p>
                                                        <p className="m-0">
                                                            <FontAwesomeIcon icon={faPrint} size="1x" className="action me-2" color={option.etiqueta ? "#215732" : '#9e9b9b'} />  
                                                            <small className={(!option.etiqueta) ? 'text-default' : '' }>{option.etiqueta ? 'Requiere etiqueta' : 'No requiere etiqueta'}</small>
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="align-middle">
                                                <div className="w-100 d-flex flex-column align-items-center">
                                                    { (fromPresupuesto) ? 
                                                        <>
                                                            { (option.transportista_id !== 1) ?
                                                                <>
                                                                    <input 
                                                                    type="number" 
                                                                    step="0.01"
                                                                    min={0}
                                                                    onChange={(e) => handleChangePrecioEnvio(e, option)}
                                                                    defaultValue={option.precio_transporte} />
                                                                    
                                                                    <b className="mt-2">{option.precio_tarifa}€</b>
                                                                </>
                                                                :
                                                                <>
                                                                    { (option.transportista_id === 1 && option.servicio_envio_id !== 57) ?
                                                                        <>
                                                                            { destino && destino.provincia !== 'Baleares' ?
                                                                                <>
                                                                                    <b>{option.precio_transporte}€</b>
                                                                                    <small>Sólo Península</small>
                                                                                </>
                                                                                : 
                                                                                <input 
                                                                                    type="number" 
                                                                                    step="0.01"
                                                                                    min={0}
                                                                                    onChange={(e) => handleChangePrecioEnvio(e, option)}
                                                                                    defaultValue={option.precio_transporte} />
                                                                            }
                                                                        </>
                                                                        : 
                                                                        <>   
                                                                            { option.servicio_envio_id === 57 ?
                                                                                <>
                                                                                    <input 
                                                                                        type="text" 
                                                                                        className="mb-2"
                                                                                        placeholder="ID de recodiga"
                                                                                        onChange={(e) => handleChangeRecodigaID(e, option)}
                                                                                        defaultValue={""} />

                                                                                    <b>{option.precio_transporte}€</b>  
                                                                                </>
                                                                                : <b>{option.precio_transporte}€</b>  
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                        
                                                    :
                                                    <>
                                                        {(isVoluminoso && option.transportista_id === 1 && option.servicio_envio_id !== 57) ?
                                                            <>
                                                                { destino && destino.provincia !== 'Baleares' ?
                                                                    <>
                                                                        <b>{option.precio_transporte}€</b>
                                                                        <small>Sólo Península</small>
                                                                    </>
                                                                    : 
                                                                    <input 
                                                                        type="number" 
                                                                        step="0.01"
                                                                        className="form-control" 
                                                                        min={0}
                                                                        onChange={(e) => handleChangePrecioEnvio(e, option)}
                                                                        defaultValue={option.precio_transporte} />
                                                                }
                                                            </>
                                                            : <b>{option.precio_transporte}€</b>
                                                        }
                                                    </>
                                                    }
                                                </div>
                                            </td>
                                            <td className="align-middle text-center" width="10%">
                                                { option.recomendado ?
                                                    <span className="recomendado">recomendado</span>
                                                    :  ''
                                                }
                                            </td>
                                            <td width="15%" className="text-end align-middle">
                                                <button className="btn btn-primary btn-sm" type="button" onClick={() => handleSelectServicio(option, i)}>SELECCIONAR</button>
                                            </td>
                                        </tr>
                                    )})
                                    : 
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="action" color="#ffc107" /> 
                                            No hay servicios de envio para este destino.
                                            <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="action" color="#ffc107" /> 
                                        </td>
                                    </tr>
                                }
                                </>
                                 : 
                                 <tr>
                                    <td colSpan="5"><Loading /></td>
                                 </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
     )
}
