import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { Box, MenuItem, Select } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import moment from 'moment';
import { faEuroSign, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isBoolean } from "../../../../../../helpers/functionalities";
import { useForm } from "react-hook-form";
import VirtualizedAutocompletePersonas from "../../../../../AutocompletePersonas";
import AutocompleteSelect from "../../../../../AutocompleteSelect";

export default function Advanced({
    detalleTab,
    pageName,
    reset,
    onChange
}) {
    const user = JSON.parse(localStorage.getItem('user'));
    const recambistas = useSelector(state => {
        return state.recambistas
    });
    const transportistas = useSelector(state => {
        return state.transportistas
    });
    const atencionCliente = useSelector(state => {
        return state.atencion_cliente
    });
    const administracion = useSelector(state => {
        return state.administracion
    });
    const comerciales = useSelector(state => {
        return state.comerciales
    });
    const tipoIncidencias = useSelector(state => {
        return state.tipo_incidencias
    });
    const plataformas = useSelector(state => {
        return state.plataformas
    })
    const tipo_piezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    const tipo_pagos = useSelector(state => {
        const { tipo_pagos } = state;
        return tipo_pagos;
    });
    const searcher = useSelector(state => {
        return state.search
    });
    const { 
        control } = useForm({
        defaultValues: {
            proveedor: null
        }
    });
    const [search, setSearch] = useState('');
    const [startDateValue, setStartDateValue] = useState(null);
    const [endDateValue, setEndDateValue] = useState(null);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [responsable, setResponsable] = useState(-1);
    const [creadoPor, setCreadoPor] = useState(user.rol_id === 5 ? user.id : -1);
    const [transportista, setTransportista] = useState(-1);
    const [comercial, setComercial] = useState(null);
    const [tipo, setTipo] = useState(pageName === 'comerciales' ? 'validos' : 'todos');
    const [credito, setCredito] = useState(-1);
    const [integracion, setIntegracion] = useState(null);
    const [vales, setVales] = useState(null);
    const [confirmacion, setConfirmacion] = useState(-1);
    const [plataforma, setPlataforma] = useState(-1);
    const [tipoPago, setTipoPago] = useState(-1);
    const [solucionProveedor, setSolucionProveedor] = useState(-1);
    const [solucionCliente, setSolucionCliente] = useState(-1);
    const [responsables, setResponsables] = useState(null);
    const [personasIncidencias, setPersonasIncidencias] = useState(null);
    const [culpa, setCulpa] = useState('todos');
    const [proveedor, setProveedor] = useState(null);
    const [tipoPiezas, setTipoPiezas] = useState([]);

    useEffect(() => {
        if(reset) {
            setSearch('');
            setStartDateValue(null);
            setEndDateValue(null);
            setResponsable(-1);
            setCreadoPor(user.rol_id === 5 ? user.id : -1)
            setTransportista(-1);
            setComercial(null);
            setTipo(pageName === 'comerciales' ? 'validos' : 'todos');
            setMax(0);
            setMin(0);
            setConfirmacion(-1);
            setSolucionProveedor(-1);
            setSolucionCliente(-1);
            setCredito(-1);
            setIntegracion(null);
            setVales(null);
            setCulpa('todos');
            setProveedor(null);
            setTipoPiezas([]);
            setPlataforma(-1);
            setTipoPago(-1);
        } else {
            if(searcher) {
                if(searcher?.search) setSearch(searcher?.search);
                if(searcher?.start_date) setStartDateValue(searcher?.start_date);
                if(searcher?.end_date) setEndDateValue(searcher?.end_date);
                if(searcher?.responsable) setResponsable(searcher?.responsable);
                if(searcher?.creado_por) setCreadoPor(searcher?.creado_por);
                if(searcher?.transportista) setTransportista(searcher?.transportista);
                if(searcher?.comercial) setComercial(searcher?.comercial);
                if(searcher?.tipo) setTipo(searcher?.tipo ? searcher?.tipo : (pageName === 'comerciales' ? 'validos' : 'todos'));
                if(searcher?.min) setMin(searcher?.min);
                if(searcher?.max) setMax(searcher?.max);
                if(searcher?.culpa) setCulpa(searcher?.culpa ? searcher?.culpa : 'todos');
                if([0, 1, -1].includes(searcher?.credito)) setCredito(searcher?.credito);
                if(searcher?.integracion) setIntegracion(searcher?.integracion ? 1 : 0);
                if(searcher?.vales) setVales(searcher?.vales ? 1 : 0);
                if(searcher?.confirmacion) setConfirmacion(searcher?.confirmacion ? 1 : -1);
                if(searcher?.proveedor) setProveedor(searcher?.proveedor ? searcher?.proveedor : null);
                if(searcher?.tipo_piezas) setTipoPiezas(searcher?.tipo_piezas ? searcher?.tipo_piezas : []);
                if(searcher?.solucion_proveedor) setSolucionProveedor(searcher?.solucion_proveedor ? 1 : -1);
                if(searcher?.solucion_cliente) setSolucionCliente(searcher?.solucion_cliente ? 1 : -1);
                if(searcher?.plataforma) setPlataforma(searcher?.plataforma ? searcher?.plataforma : -1);
                if(searcher?.tipo_pago) setTipoPago(searcher?.tipo_pago ? searcher?.tipo_pago : -1);
            }
        }

        setResponsables(recambistas);
        setPersonasIncidencias([...atencionCliente, ...administracion, ...recambistas]);

        if(detalleTab === 'cliente' || detalleTab === 'lead') setResponsable(-1);
    }, [searcher, reset, detalleTab]);

    const handleSearch = (newValue, type) => {
        const searchValue = (newValue && type === 'search') ? newValue : search;
        const com = (newValue && type === 'com') ? parseInt(newValue) : parseInt(comercial);
        const resp = (newValue && type === 'resp') ? parseInt(newValue) : parseInt(responsable);
        const creado = (newValue && type === 'creado') ? parseInt(newValue) : parseInt(creadoPor);
        const trans = (newValue && type === 'trans') ? parseInt(newValue) : parseInt(transportista);
        const start = startDateValue ? moment(startDateValue).format('YYYY-MM-DD') : null;
        const end = (newValue && type === 'end') ? moment(newValue).format('YYYY-MM-DD') : endDateValue ? moment(endDateValue).format('YYYY-MM-DD') : null;
        const cred = (type === 'credito') ? parseInt(newValue) : parseInt(credito);
        const integ = (type === 'integracion') ? newValue : (integracion === 1 ? true : null);
        const val = (type === 'vales') ? newValue : (vales === 1 ? true : null);
        const conf = (type === 'confirmacion') ? newValue : (confirmacion === 1 ? true : confirmacion === 0 ? false : -1);
        const solProv = (type === 'sol_prov') ? newValue : (solucionProveedor === 1 ? true : solucionProveedor === 0 ? false : -1);
        const solCli = (type === 'sol_cli') ? newValue : (solucionCliente === 1 ? true : solucionCliente === 0 ? false : -1);
        const tip = (type === 'tipo') ? newValue : tipo;
        const culp = (newValue && type === 'culpa') ? newValue : culpa;
        const prov = (newValue && type === 'proveedor') ? newValue : proveedor;
        const tp = (newValue && type === 'tipo_piezas') ? newValue : tipoPiezas;
        const plt = (newValue && type === 'plataforma') ? newValue : plataforma;
        const tpPg = (newValue && type === 'tipo_pago') ? newValue : tipoPago;

        var searchData = {
            search: searchValue,
            start: start,
            end: end,
            responsable: resp,
            creado_por: creado,
            comercial: com,
            transportista: trans,
            min: min,
            max: max,
            tipo: tip,
            culpa: culp,
            credito: cred,
        }

        if(start) { searchData.start_date = start; }
        if(end) { searchData.end_date = end; }
        if(resp && resp !== -1) { searchData.responsable = resp; }
        if(creado && creado !== -1) { searchData.creado_por = creado; }
        if(com && com !== -1) { searchData.comercial = com; }
        if(trans && trans !== -1) { searchData.transportista = trans; }
        if(min) { searchData.min = min; }
        if(max) { searchData.max = max; }
        if(tip) { searchData.tipo = tip; }
        if(culp) { searchData.culpa = culp; }
        if(plt) {searchData.plataforma = plt; }
        if(tpPg) { searchData.tipo_pago = tpPg; }

        if(prov) { searchData.proveedor = prov; }
        if(tp && tp?.length > 0) { searchData.tipo_piezas = tp; }

        if(isBoolean(integ)) {searchData.integracion = integ;}
        if(isBoolean(conf)) {searchData.confirmacion = conf;}
        if(isBoolean(val)) { searchData.vales = val; }
        if(isBoolean(solProv)) { searchData.solucion_proveedor = solProv; }
        if(isBoolean(solCli)) { searchData.solucion_cliente = solCli; }

        onChange(searchData);
    };

    const handleResponsable = (e) => { 
        const value = parseInt(e.target.value);
        setResponsable(value)
        handleSearch(value, 'resp');
    }

    const handleResponsableIncidencias = (e) => { 
        const value = parseInt(e.target.value);
        setCreadoPor(value)
        handleSearch(value, 'creado');
    }

    const handleTransportista = (e) => { 
        const value = parseInt(e.target.value);
        setTransportista(value)
        handleSearch(value, 'trans');
    }

    const handleComercial = (e) => { 
        const value = parseInt(e.target.value);
        setComercial(value)
        handleSearch(value, 'com');
    }

    const handleTipo = (e) => {
        setComercial(e.target.value)
        handleSearch(e.target.value, 'tipo');
    }

    const handleCulpa = (e) => {
        setComercial(e.target.value)
        handleSearch(e.target.value, 'culpa');
    }

    const handleChangeMin = (event) => setMin(event.target.value);
    const handleChangeMax = (event) => setMax(event.target.value);

    const handleChangeCredito = (e) => {
        setCredito(e.target.value);
        handleSearch(e.target.value, 'credito');
    }
    const handleChangeIntegracion = (event) => {
        const value = parseInt(event.target.value) === 1 ? true : false;
        setIntegracion(value);
        handleSearch(value, 'integracion');
    }
    const handleChangeConfirmacion = (event) => {
        const value = parseInt(event.target.value) === 1 ? true : (parseInt(event.target.value) === 0 ? false : -1);
        setConfirmacion(value);
        handleSearch(value, 'confirmacion');
    }
    const handleChangePlataforma = (event) => {
        const value = parseInt(event.target.value);
        setPlataforma(value);
        handleSearch(value, 'plataforma');
    }
    const handleChangeTipoPago = (event) => {
        const value = parseInt(event.target.value);
        setTipoPago(value);
        handleSearch(value, 'tipo_pago');
    }
    const handleChangeSolucionProveedor = (event) => {
        const value = parseInt(event.target.value) === 1 ? true : (parseInt(event.target.value) === 0 ? false : -1);
        setConfirmacion(value);
        handleSearch(value, 'sol_prov');
    }
    const handleChangeSolucionCliente = (event) => {
        const value = parseInt(event.target.value) === 1 ? true : (parseInt(event.target.value) === 0 ? false : -1);
        setConfirmacion(value);
        handleSearch(value, 'sol_cli');
    }
    const handleChangeVales = (event) => {
        const value = parseInt(event.target.value) === 1 ? true : false;
        setVales(value);
        handleSearch(value, 'vales');
    }

    const handleChange = (event) => {
        setSearch(event.target.value);
    }

    const handleChangeProveedor = (proveedor) => {
        if(proveedor !== "" && proveedor !== undefined && proveedor !== null) {
            setProveedor(proveedor)
            handleSearch(proveedor, 'proveedor');
        }
    }

    const handleChangeTipoPiezas = (tipPiezas) => {
        if(tipPiezas !== undefined && tipPiezas !== null) {
            if(tipPiezas?.length > 0) {
                setTipoPiezas(tipoPiezas)
                handleSearch(tipPiezas, 'tipo_piezas');
            } else { 
                setTipoPiezas([])
                handleSearch([], 'tipo_piezas');
            }
        }
    }

    return (
        <div className="flex-grow-1 d-flex flex-wrap">
            { (pageName === 'presupuestos' || pageName === 'ecommerce' || pageName === 'ventas' || pageName === 'devoluciones' || pageName === 'incidencias') ?
                <div className="d-flex flex-grow-1 flex-wrap flex-md-row align-items-end justify-content-start">
                    { (pageName !== 'incidencias') ?
                        <div className="d-flex flex-wrap flex-md-nowrap align-items-center me-0 me-md-3">
                            <div className="form-group m-0">
                                <small>Importe (con IVA)</small>

                                <div className="d-flex align-items-center">
                                    <div className="position-relative">
                                        <input 
                                            type="number" 
                                            name="min" 
                                            step={0.1}
                                            className="form-control" 
                                            value={min} 
                                            onChange={handleChangeMin}
                                            placeholder="mínimo"
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') {
                                                    handleSearch(null);
                                            }}}  
                                            />
                                        <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
                                    </div>

                                    <div className="position-relative ms-3">
                                        <input 
                                            type="number" 
                                            name="max" 
                                            step={0.1}
                                            className="form-control" 
                                            value={max} 
                                            placeholder="máximo"
                                            onChange={handleChangeMax}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') {
                                                    handleSearch(null);
                                                }}}  
                                            />
                                        <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        : ''
                    }
                    
                    <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                        <div className="form-group m-0">
                            <small>Fecha</small>

                            <div className="d-flex align-items-center">
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                    <DatePicker
                                        disableFuture
                                        label=""
                                        value={startDateValue}
                                        onChange={(newValue) => {
                                            setStartDateValue(newValue);
                                        }}
                                        mask={'__/__/____'}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box className="date-picker me-3" sx={{ position: 'relative' }}>
                                                <input className="form-control" ref={inputRef} {...inputProps} />
                                                {InputProps?.endAdornment}
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                    <DatePicker
                                        disableFuture
                                        label=""
                                        value={endDateValue}
                                        onChange={(newValue) => {
                                            setEndDateValue(newValue);
                                            handleSearch(newValue, 'end');
                                        }}
                                        mask={'__/__/____'}
                                        inputFormat="dd/MM/yyyy"
                                        minDate={startDateValue}
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box className="date-picker" sx={{ position: 'relative' }}>
                                                <input className="form-control" ref={inputRef} {...inputProps} />
                                                {InputProps?.endAdornment}
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>

                    { (pageName === 'ventas' || pageName === 'devoluciones' || pageName === 'presupuestos' || pageName === 'ecommerce') ?
                        <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                            <div className="form-group m-0">
                                <small>Responsable</small>
                                { (recambistas && recambistas.length > 0) &&
                                    <select 
                                        id="responsable"
                                        value={responsable ? responsable : -1}
                                        onChange={handleResponsable}>
                                        <option key={'recambistas-' + 0} value={-1}>Todos</option>
                                        {recambistas.map(op => <option key={'recambistas-' + op.id} value={op.id}>{op.username}</option>)}
                                    </select>
                                }
                            </div>
                        </div>
                        : ''
                    }

                    { (pageName === 'ventas') ?
                        <>
                        <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                            <div className="form-group m-0">
                                <small>Confirmación</small>

                                <Select
                                    size="small"
                                    name="confirmacion"
                                    id="confirmacion"
                                    onChange={handleChangeConfirmacion}
                                    value={confirmacion ? confirmacion : -1}>
                                        <MenuItem 
                                            key={'filter-confirmacion--1'}
                                            value={-1}>
                                            {"Todos"}
                                        </MenuItem>
                                        <MenuItem 
                                            key={'filter-confirmacion-0'}
                                            value={0}>
                                            {"No"}
                                        </MenuItem>
                                        <MenuItem 
                                            key={'filter-confirmacion-1'}
                                            value={1}>
                                            {"Si"}
                                        </MenuItem>
                                </Select>
                            </div>
                        </div>
                        <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                            <div className="form-group m-0">
                                <small>Plataforma</small>

                                <Select
                                    size="small"
                                    name="plataforma"
                                    id="plataforma"
                                    onChange={handleChangePlataforma}
                                    value={plataforma}>
                                        <MenuItem 
                                            key={'filter-plataforma--1'}
                                            value={-1}>
                                            {"Todas"}
                                        </MenuItem>
                                        {
                                            plataformas.map(item => (
                                                <MenuItem 
                                                    key={`filter-plataforma-${item.id}`}
                                                    value={item.id}>
                                                    {item.nombre}
                                                </MenuItem>
                                            ))
                                        }
                                </Select>
                            </div>
                        </div>

                        <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                            <div className="form-group m-0">
                                <small>Tipo de pago</small>

                                <Select
                                    size="small"
                                    name="tipo_pago"
                                    id="tipo_pago"
                                    onChange={handleChangeTipoPago}
                                    value={tipoPago}>
                                        <MenuItem 
                                            key={'filter-tp--1'}
                                            value={-1}>
                                            {"Todos"}
                                        </MenuItem>
                                        {
                                            tipo_pagos.map(item => (
                                                <MenuItem 
                                                    key={`filter-tp-${item.id}`}
                                                    value={item.id}>
                                                    {item.nombre}
                                                </MenuItem>
                                            ))
                                        }
                                </Select>
                            </div>
                        </div>
                        </>
                        : ''
                    }

                    { (pageName === 'incidencias') ?
                        <>
                            <div className="d-flex align-items-center mt-3 mt-md-0">
                                <div className="form-group m-0 me-0 me-md-3">
                                    <small>Responsable</small>
                                    { (personasIncidencias && personasIncidencias.length > 0) &&
                                            <select 
                                                id="responsable"
                                                value={responsable ? responsable : -1}
                                                onChange={handleResponsable}>
                                                <option key={'recambistas-' + 0} value={-1}>Todos</option>
                                                {personasIncidencias.map(op => <option key={'recambistas-' + op.id} value={op.id}>{op.username}</option>)}
                                            </select>
                                    }
                                </div>

                                <div className="form-group m-0 me-0 me-md-3">
                                    <small>Creado por</small>
                                    { (personasIncidencias && personasIncidencias.length > 0) &&
                                            <select 
                                                id="creado_por"
                                                value={creadoPor ? creadoPor : -1}
                                                onChange={handleResponsableIncidencias}>
                                                <option key={'incidencias-' + 0} value={-1}>Todos</option>
                                                {personasIncidencias.map(op => <option key={'incidencias-' + op.id} value={op.id}>{op.username}</option>)}
                                            </select>
                                    }
                                </div>
                            </div>

                            <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                                <div className="form-group m-0">
                                    <small>Tipo</small>
                                    { (tipoIncidencias && tipoIncidencias.length > 0) &&
                                            <select 
                                                id="tipo"
                                                value={tipo ? tipo : 'todos'}
                                                onChange={handleTipo}>
                                                <option key={'tipo-' + 0} value={'todos'}>Todos</option>
                                                {tipoIncidencias.map(op => <option key={'tipo-' + op.id} value={op.id}>{op.nombre}</option>)}
                                            </select>
                                    }
                                </div>
                            </div>

                            <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                                <div className="form-group m-0">
                                    <small>Culpa</small>
                                    <select 
                                        id="culpa"
                                        value={culpa ? culpa : 'todos'}
                                        onChange={handleCulpa}>
                                        <option value={'todos'}>Todos</option>
                                        <option value={'cliente'}>Cliente</option>
                                        <option value={'proveedor'}>Proveedor</option>
                                        <option value={'recomotor'}>Recomotor</option>
                                        <option value={'atencion-comercial'}>Atención comercial</option>
                                        <option value={'transporte-propio'}>Transporte propio</option>
                                        <option value={'transporte-proveedor'}>Transporte proveedor</option>
                                    </select>
                                </div>
                            </div>

                            <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                                <div className="form-group m-0">
                                    <small>Proveedor</small>

                                    <VirtualizedAutocompletePersonas 
                                        id={"proveedor_id"}
                                        control={control}
                                        item={'proveedor'}
                                        labels={['nombre', 'telefono', 'score']}
                                        defaultValue={proveedor}
                                        onChange={handleChangeProveedor}
                                    />
                                </div>
                            </div>

                            <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                                <div className="form-group m-0">
                                    <small>Tipo de pieza:</small>
                                    <AutocompleteSelect 
                                        options={tipo_piezas}
                                        id={'tipo_piezas'}
                                        selectedValues={tipoPiezas}
                                        onSetValue={handleChangeTipoPiezas}
                                    />
                                </div>
                            </div>
                        </>
                        : ''
                    }

                    { (pageName === 'incidencias') ?
                        <div className="d-flex align-items-stretch me-0 me-md-3 mt-3 mt-md-0">
                            <div className="form-group m-0 me-3">
                                <small>Sol. Proveedor</small>

                                <Select
                                    size="small"
                                    name="solucion_proveedor"
                                    id="solucion_proveedor"
                                    onChange={handleChangeSolucionProveedor}
                                    value={solucionProveedor ? solucionProveedor : -1}>
                                        <MenuItem 
                                            key={'filter-sol-prov--1'}
                                            value={-1}>
                                            {"Todos"}
                                        </MenuItem>
                                        <MenuItem 
                                            key={'filter-sol-prov-0'}
                                            value={0}>
                                            {"No"}
                                        </MenuItem>
                                        <MenuItem 
                                            key={'filter-sol-prov-1'}
                                            value={1}>
                                            {"Si"}
                                        </MenuItem>
                                </Select>
                            </div>

                            <div className="form-group justify-content-end m-0">
                                <small>Sol. Cliente</small>

                                <Select
                                    size="small"
                                    name="solucion_cliente"
                                    id="solucion_cliente"
                                    onChange={handleChangeSolucionCliente}
                                    value={solucionCliente ? solucionCliente : -1}>
                                        <MenuItem 
                                            key={'filter-sol-cli--1'}
                                            value={-1}>
                                            {"Todos"}
                                        </MenuItem>
                                        <MenuItem 
                                            key={'filter-sol-cli-0'}
                                            value={0}>
                                            {"No"}
                                        </MenuItem>
                                        <MenuItem 
                                            key={'filter-sol-cli-1'}
                                            value={1}>
                                            {"Si"}
                                        </MenuItem>
                                </Select>
                            </div>
                        </div>
                        : ''
                    }

                    <div className="position-relative ms-auto">
                        <input 
                            type="text" 
                            name="searcher" 
                            className="form-control" 
                            value={search} 
                            onChange={handleChange}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    handleSearch(null);
                                }}}  
                            />
                        <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" style={{cursor: 'pointer'}} onClick={() => handleSearch(null)} />
                    </div>
                </div>
            : (pageName === 'comerciales' || pageName === 'portugal') ?
                <div className="d-flex flex-grow-1 flex-column flex-md-row align-items-end">
                    <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                        <div className="form-group m-0">
                            <small>Comercial</small>
                            { (comerciales && comerciales.length > 0) &&
                                    <select 
                                        id="comercial"
                                        value={comercial ? comercial : -1}
                                        onChange={handleComercial}>
                                        <option key={'comerciales-' + 0} value={-1}>Todos</option>
                                        {comerciales.map(op => <option key={'comerciales-' + op.id} value={op.id}>{op.username}</option>)}
                                    </select>
                            }
                        </div>
                    </div>

                    <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                        <div className="form-group m-0">
                            <small>Fecha</small>

                            <div className="d-flex align-items-center">
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                    <DatePicker
                                        disableFuture
                                        label=""
                                        value={startDateValue}
                                        onChange={(newValue) => {
                                            setStartDateValue(newValue);
                                        }}
                                        mask={'__/__/____'}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box className="date-picker me-3" sx={{ position: 'relative' }}>
                                                <input className="form-control" ref={inputRef} {...inputProps} />
                                                {InputProps?.endAdornment}
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                    <DatePicker
                                        disableFuture
                                        label=""
                                        value={endDateValue}
                                        onChange={(newValue) => {
                                            setEndDateValue(newValue);
                                            handleSearch(newValue, 'end');
                                        }}
                                        mask={'__/__/____'}
                                        inputFormat="dd/MM/yyyy"
                                        minDate={startDateValue}
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box className="date-picker" sx={{ position: 'relative' }}>
                                                <input className="form-control" ref={inputRef} {...inputProps} />
                                                {InputProps?.endAdornment}
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                        <div className="form-group m-0">
                            <small>Tipo</small>
                            
                            <select 
                                id="tipo"
                                value={searcher?.tipo ? searcher?.tipo : 'validos'}
                                onChange={handleTipo}>
                                    <option value={"todos"}>Todos</option>
                                    <option value={"validos"}>Válidos</option>
                                    <option value={"no-llamar"}>No llamar</option>
                                    <option value={"no-piden"}>No piden</option>
                            </select>
                           
                        </div>
                    </div>

                    <div className="position-relative ms-auto">
                        <input 
                            type="text" 
                            name="searcher" 
                            className="form-control" 
                            value={search} 
                            onChange={handleChange}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    handleSearch(null);
                                }}}  
                            />
                        <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" style={{cursor: 'pointer'}} onClick={() => handleSearch(null)} />
                    </div>
                </div>
            : pageName === 'proveedores' ?
                <div className="d-flex flex-grow-1 flex-column flex-md-row align-items-end justify-content-between">
                    <div className="d-flex align-items-center mx-0 mt-3 mt-md-0">
                        <div className="form-group m-0">
                            <small>A Crédito</small>
                            <Select
                                size="small"
                                name="credito"
                                id="credito"
                                onChange={handleChangeCredito}
                                value={credito}>
                                    <MenuItem 
                                        key={'filter-credito--1'}
                                        value={-1}>
                                        {"Todos"}
                                    </MenuItem>
                                    <MenuItem 
                                        key={'filter-credito-0'}
                                        value={0}>
                                        {"No"}
                                    </MenuItem>
                                    <MenuItem 
                                        key={'filter-credito-1'}
                                        value={1}>
                                        {"Si"}
                                    </MenuItem>
                            </Select>
                        </div>

                        <div className="form-group m-0 ms-3">
                            <small>Integración</small>
                            <Select
                                size="small"
                                name="integracion"
                                id="integracion"
                                onChange={handleChangeIntegracion}
                                value={integracion ? integracion : 0}>
                                    <MenuItem 
                                        key={'filter-integracion-0'}
                                        value={0}>
                                        {"No"}
                                    </MenuItem>
                                    <MenuItem 
                                        key={'filter-integracion-1'}
                                        value={1}>
                                        {"Si"}
                                    </MenuItem>
                            </Select>
                        </div>

                        <div className="form-group m-0 ms-3">
                            <small>Vales</small>
                            <Select
                                size="small"
                                name="vales"
                                id="vales"
                                onChange={handleChangeVales}
                                value={vales ? vales : 0}>
                                    <MenuItem 
                                        key={'filter-vales-0'}
                                        value={0}>
                                        {"No"}
                                    </MenuItem>
                                    <MenuItem 
                                        key={'filter-vales-1'}
                                        value={1}>
                                        {"Si"}
                                    </MenuItem>
                            </Select>
                        </div>
                    </div>

                    <div className="position-relative ms-auto">
                        <input 
                            type="text" 
                            name="searcher" 
                            className="form-control" 
                            value={search} 
                            onChange={handleChange}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    handleSearch(null);
                                }}}  
                            />
                        <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" style={{cursor: 'pointer'}} onClick={() => handleSearch(null)} />
                    </div>
                </div>
            : pageName === 'clientes' ?
                <div className="d-flex flex-grow-1 flex-column flex-md-row align-items-end justify-content-between">
                    <div className="d-flex align-items-center mx-0 mt-3 mt-md-0">
                        <div className="form-group m-0">
                            <small>A Crédito</small>
                            <Select
                                size="small"
                                name="credito"
                                id="credito"
                                onChange={handleChangeCredito}
                                value={credito !== null ? credito : -1}>
                                    <MenuItem 
                                        key={'filter-credito--1'}
                                        value={-1}>
                                        {"Todos"}
                                    </MenuItem>
                                    <MenuItem 
                                        key={'filter-credito-0'}
                                        value={0}>
                                        {"No"}
                                    </MenuItem>
                                    <MenuItem 
                                        key={'filter-credito-1'}
                                        value={1}>
                                        {"Si"}
                                    </MenuItem>
                            </Select>
                        </div>
                    </div>

                    <div className="position-relative ms-auto">
                        <input 
                            type="text" 
                            name="searcher" 
                            className="form-control" 
                            value={search} 
                            onChange={handleChange}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    handleSearch(null);
                                }}}  
                            />
                        <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" style={{cursor: 'pointer'}} onClick={() => handleSearch(null)} />
                    </div>
                </div>
            : (pageName === 'envios' || pageName === 'solicitudes') ?
                <div className="d-flex flex-grow-1 flex-column flex-md-row align-items-end justify-content-between">
                    <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                        <div className="form-group m-0">
                            <small>Responsable</small>
                            { (responsables && responsables.length > 0) &&
                                    <select 
                                        id="responsable"
                                        value={responsable ? responsable : -1}
                                        onChange={handleResponsable}>
                                        <option key={'recambistas-' + 0} value={-1}>Todos</option>
                                        {responsables.map(op => <option key={'recambistas-' + op.id} value={op.id}>{op.username}</option>)}
                                    </select>
                            }
                        </div>
                    </div>

                    <div className="d-flex align-items-center">
                        <div className="form-group m-0">
                            <small>Transportista</small>
                            { (transportistas && transportistas.length > 0) &&
                                <select 
                                    id="transportista"
                                    value={transportista ? transportista : -1}
                                    onChange={handleTransportista}>
                                    <option key={'transportista-' + 0} value={-1}>Todos</option>
                                    {transportistas.map(op => <option key={'transportista-' + op.id} value={op.id}>{op.nombre}</option>)}
                                </select>
                            }
                        </div>
                    </div>

                    <div className="position-relative ms-auto">
                        <input 
                            type="text" 
                            name="searcher" 
                            className="form-control" 
                            value={search} 
                            onChange={handleChange}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    handleSearch(null);
                                }}}  
                            />
                        <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" style={{cursor: 'pointer'}} onClick={() => handleSearch(null)} />
                    </div>
                </div>
            :
                <div className="d-flex flex-grow-1 flex-column flex-md-row align-items-end justify-content-between">
                    <div className="position-relative ms-auto">
                        <input 
                            type="text" 
                            name="searcher" 
                            className="form-control" 
                            value={search} 
                            onChange={handleChange}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    handleSearch(null);
                                }}}  
                            />
                        <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" style={{cursor: 'pointer'}} onClick={() => handleSearch(null)} />
                    </div>
                </div>
            }
        </div>
    );
}