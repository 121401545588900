import { toast } from "react-toastify";
import {
  getPiezasReservadas,
  addReservaPieza,
  updateReservaPieza,
} from "../views/Dashboard/components/shared/services/piezasReservadas";
import {
  asyncMac,
  makeAsyncTypes,
  makeFetchingReducer,
  makeSetReducer,
  reduceReducers,
} from "./utils";

const asyncPiezasReservadas = makeAsyncTypes("piezasReservadas");

const [setPending, setFullFilled, setError] = asyncMac(asyncPiezasReservadas);

export const piezasReservadasFetchingReducer = makeFetchingReducer([
  "piezasReservadas/pending",
  "piezasReservadas/fullfilled",
  "piezasReservadas/rejected",
]);

const fullfilledReducer = makeSetReducer(["piezasReservadas/fullfilled"]);

export const fetchPiezasReservadas = () => async (dispatch) => {
  dispatch(setPending());

  try {
    const response = await getPiezasReservadas();
    const data = await response.data;
    dispatch(setFullFilled(data));
  } catch (e) {
    dispatch(setError(e.message));
  }
};

export const addReserva = (data) => async (dispatch) => {
  try {
    const response = await addReservaPieza(data);

    if (response && response.success) {
      toast.success(response.message);
    }
  } catch (e) {
    dispatch(setError(e.message));
  }
};

export const updateReserva = (id) => async (dispatch) => {
  try {
    const response = await updateReservaPieza(id);

    if (response && response.success) {
      toast.success(response.message);
    }
  } catch (e) {
    dispatch(setError(e.message));
  }
};

export const piezasReservadasReducer = fullfilledReducer;
export const piezasReservadasStatusReducer = piezasReservadasFetchingReducer;
